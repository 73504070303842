import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  HTTPURL,
  APPID,
  APPLOCATION,
  SQUARESCRIPT,
} from "../../../constants/Matcher";
import { MyCalendar } from "../../common/MyCalendar";
import { Radio } from "../../common/Radio";

import Select from "react-select";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "../../../styles/PlaceOrder.css";
import { v4 as uuidv4 } from "uuid";
import CreditTable from "./CreditTable";

const RemoveCredit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [amount, setAmount] = useState(100);
  const [message, setMessage] = useState('');
  const [credit, setCredit] = useState(0);
  const [user, setUser] = useState([]);

  const [cards, setCards] = useState();
  const [card, setCard] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isBtn, setIsBtn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isCardLoaded, setIsCardLoaded] = useState(false);
  const [idempotencykey, setIdempotencykey] = useState("");

  useEffect(() => {
    getUser();
    getCredit()
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });

    setIdempotencykey(uuidv4());
    if (user?.id > 0) {
      const script = document.createElement("script");
      script.src = SQUARESCRIPT;
      script.async = true;
      script.onload = () => setLoad();
      document.body.appendChild(script);
    }
    //  getPrivacyAndTerms();
  }, [user]);

  const getUser = () => {
    axios
      .get(HTTPURL + "/api/get-User/" + params.id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // console.log(response?.data);
        if (response?.data?.status) {
          setUser(response?.data?.data);
        } else {
          toast.error(response?.data?.message);
        }
      });
  };



  const getCredit = () => {
    axios
      .get(HTTPURL + "/api/get-credit/" + params.id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response?.data?.status) {
          setCredit(response?.data?.credit);
        } else {
          toast.error(response?.data?.message);
        }
      });
  };

  const setLoad = async () => {
    const initializeCard = async (payments) => {
      const card = await payments.card();

      await card.attach("#card-container");

      return card;
    };

  document
  .getElementById("card-button")
  .addEventListener("click", async function (event) {
    setIsBtn(true);
    const amount = event.target.getAttribute("amount");
    const card = event.target.getAttribute("card");
    const message = event.target.getAttribute("message");
    const idempotencykey = event.target.getAttribute("idempotencykey");


    try {
      
    

      let data = {
        locationId: APPLOCATION,
        idempotencyKey: idempotencykey,
        sourceId: 'manuall',
        amount: amount,
        message: message,
        card_status: isChecked,
        user_id: params.id,
      };
    

      const tokenVal = localStorage.getItem("token");

      axios
        .post(HTTPURL + "/api/remove-credit/" + params.id, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${tokenVal}`,
          },
        })
        .then(function (response) {
          setIsBtn(false);
          if (response?.data?.status) {
            console.log(response?.data);
            toast.success("('Credit has been added successfully.");
            getCredit();
           
            setIdempotencykey(uuidv4());

            // navigate("/thanks-request-pickups")
          } else {
            console.log(response?.data);
            toast.error(response?.data?.message);
          }
        })
        .catch(function (error) {
          setIsBtn(false);
          toast.error(error.message);
        });

      // const paymentResults = await createPayment(token);
    } catch (e) {
      setIsBtn(false);
      toast.error("Payment is not done, please try later");
      console.error(e.message);
    }
  });
  }
  return (
    <Fragment>
      {(!user) && (
        <div className="loader">
          <div className="loader-text text-center">
            <h2>Loading Info.....</h2>
          </div>
        </div>
      )}
            <div className="container-fluid mt-5">

      <div
        className={
          user
            ? "Uf-registration place-order"
            : "d-none"
        }
      >
        <div
          className="right-nav admin-applications-content "
          id="applications-content"
        >
          <form>
            <h3 className="pb-3">Remove Credit from {user?.name}</h3>
            <div className="row  pb-2">
              <div className="col-12 ">
                <div className="row ">
                  <div className="col-12 ">
                    <label className="min-label">
                       Available Balance:{" "}
                    </label>{" "}
                    <span className="bold-text">USD {credit}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 d-md-inline-flex align-items-center">
                <label className="min-label">
                  Enter amount to be Removed <sup>*</sup>:&nbsp;&nbsp;
                </label>
                <input
                  style={{ width: "100px" }}
                  className="form-control mt-2"
                  min='1'
                  type="number"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />{" "}
                <label className="min-label ps-1">USD</label>
              </div>
            </div>
        
          
            <div className="row mb-4">
              <div className="col-12 d-md-inline-flex align-items-center">
                
               
                <textarea name="message"     value={message}
                  onChange={(e) => setMessage(e.target.value)} placeholder="ADD Special Note"  className="form-control mt-2 w-50 text-start" id="" cols="30" rows="5" ></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="combo-btn">
                  <div className="sap-blue-btn">
                    <button
                      className="btn btn-lg btn-success"
                      amount={amount}
                      card={card}
                      idempotencykey={idempotencykey}
                      message={message}
                      id="card-button"
                      type="button"
                      disabled={isBtn}
                    >
                      Remove ${amount}
                      {isBtn ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>

                <div id="payment-status-container"></div>
              </div>
            </div>
          </form>

        
        </div>
      </div>
      </div>
    </Fragment>
  );
};
export default RemoveCredit;
