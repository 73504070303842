import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";
import axios from "axios";

export const GetCounties = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-counties`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const AddCounty = async (title, zip_code_list, city_name, status) => {
  try {
    let data = {
      title: title,
      zip_code_list: zip_code_list,
      city_name: city_name,
      status: status,
    };
    const response = await axios.post(HTTPURL + "/api/add-county", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {}
};


export const AddOrderStatus = async (
  status,
  label,
  color
) => {
  try {
    let data = {
      status: status,
      label: label,
      color: color,
    };
    const response = await axios.post(
      HTTPURL + "/api/add-order-status",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (error) {}
};

export const AddReferral = async (
  referral_threshold,
  free_laundry_amount,
  status
) => {
  try {
    let data = {
      referral_threshold: referral_threshold,
      free_laundry_amount: free_laundry_amount,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + "/api/add-referralsetting",
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response.data;
  } catch (error) {}
};

export const GetReferral = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-referralsetting`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};
export const GetStatusList = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-status-list`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};
export const UpdateOrderStatus = async (
  id,
  status,
  label,
  color
) => {
  try {
    let data = {
      id: id,
      status: status,
      label: label,
      color: color,
    };
    const response = await axios.post(HTTPURL + `/api/update-order-status`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};
export const UpdateReferral = async (
  id,
  referral_threshold,
  free_laundry_amount,
  status
) => {
  try {
    let data = {
      id: id,
      referral_threshold: referral_threshold,
      free_laundry_amount: free_laundry_amount,
      status: status,
    };
    const response = await axios.post(HTTPURL + `/api/update-referralsetting`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const UpdateCounty = async (
  id,
  title,
  zip_code_list,
  city_name,
  status
) => {
  try {
    let data = {
      id: id,
      title: title,
      zip_code_list: zip_code_list,
      city_name: city_name,
      status: status,
    };
    const response = await axios.post(HTTPURL + `/api/update-county`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const updateReferralStatus = async (id, status) => {
  try {
    let data = {
      id: id,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-referral-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const UpdateCountyStatus = async (id, status) => {
  try {
    let data = {
      id: id,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-county-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};
export const DeleteOrderStatus = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(HTTPURL + `/api/delete-order-status`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const DeleteCounty = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(HTTPURL + `/api/delete-county`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const DeleteReferral = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(
      HTTPURL + `/api/delete-referralsetting`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const GetTimeSlots = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-time-slots`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const AddTimeSlot = async (start_time, end_time, status) => {
  try {
    let data = {
      start_time: start_time,
      end_time: end_time,
      status: status,
    };
    const response = await axios.post(HTTPURL + "/api/add-time-slot", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {}
};

export const UpdateTimeSlot = async (id, start_time, end_time, status) => {
  try {
    let data = {
      id: id,
      start_time: start_time,
      end_time: end_time,
      status: status,
    };
    const response = await axios.post(HTTPURL + `/api/update-time-slot`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const UpdateTimeSlotStatus = async (id, status) => {
  try {
    let data = {
      id: id,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-time-slot-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const DeleteTimeSlot = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(HTTPURL + `/api/delete-time-slot`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const GetDeliveryDays = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-delivery-days`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const AddDeliveryDay = async (delivery_day, days_gap, status) => {
  try {
    let data = {
      delivery_day: delivery_day,
      days_gap: days_gap,
      status: status,
    };
    const response = await axios.post(HTTPURL + "/api/add-delivery-day", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {}
};

export const UpdateDeliveryDay = async (id, delivery_day, days_gap, status) => {
  try {
    let data = {
      id: id,
      delivery_day: delivery_day,
      days_gap: days_gap,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-delivery-day`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const UpdateDeliveryDayStatus = async (id, status) => {
  try {
    let data = {
      id: id,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-delivery-day-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const DeleteDeliveryDay = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(
      HTTPURL + `/api/delete-delivery-day`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const GetCategories = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-categories`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const AddCategory = async (
  image_icon,
  title,
  unit,
  upfront_amount,
  status
) => {
  try {
    let data = {
      image_icon: image_icon,
      title: title,
      unit: unit,
      upfront_amount: upfront_amount,
      status: status ? 1 : 0,
    };
    const response = await axios.post(HTTPURL + "/api/add-category", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {}
};

export const UpdateCategory = async (
  id,
  image_icon,
  old_image_icon,
  title,
  unit,
  upfront_amount,
  status
) => {
  try {
    let content_type = "multipart/form-data";
    let data = {
      id: id,
      image_icon: image_icon,
      old_image_icon: old_image_icon,
      title: title,
      unit: unit,
      upfront_amount: upfront_amount,
      status: status,
    };
    if (!image_icon) {
      data.no_uploads = 1;
      content_type = "application/json";
    }
    const response = await axios.post(HTTPURL + `/api/update-category`, data, {
      headers: {
        "Content-Type": content_type,
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const UpdateCategoryStatus = async (id, status) => {
  try {
    let data = {
      id: id,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-category-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const DeleteCategory = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(HTTPURL + `/api/delete-category`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const GetPrices = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-prices`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const AddPrice = async (
  counties,
  category,
  delivery_day,
  price_title,
  price,
  min_order,
  is_on_demand,
  status
) => {
  try {
    let data = {
      counties: counties,
      category: category?.id,
      delivery_day: delivery_day?.id,
      price_title: price_title,
      price: price,
      min_order: min_order,
      is_on_demand: is_on_demand,
      status: status,
    };
    const response = await axios.post(HTTPURL + "/api/add-price", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {}
};

export const UpdatePrice = async (
  id,
  delivery_day,
  price_title,
  price,
  min_order,
  is_on_demand,
  status
) => {
  try {
    let data = {
      id: id,
      delivery_day: delivery_day,
      price_title: price_title,
      price: price,
      min_order: min_order,
      is_on_demand: is_on_demand,
      status: status,
    };

    const response = await axios.post(HTTPURL + `/api/update-price`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const UpdatePriceStatus = async (id, status) => {
  try {
    let data = {
      id: id,
      status: status,
    };
    const response = await axios.post(
      HTTPURL + `/api/update-price-status`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const DeletePrice = async (id) => {
  try {
    let data = {
      id: id,
    };
    const response = await axios.post(HTTPURL + `/api/delete-price`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const GetHolidays = async (county = 0) => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-holidays/` + county, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {}
};

export const AddHoliday = async (county = 0, date, do_remove) => {
  try {
    let data = {
      county: county,
      date: date,
      do_remove: do_remove,
    };
    const response = await axios.post(HTTPURL + "/api/add-holiday", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token"),
      },
    });
    return response.data;
  } catch (error) {}
};
