import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import {
  GetCounties,
  AddCounty,
  UpdateCounty,
  UpdateCountyStatus,
  DeleteCounty,
  AddReferral,
  GetReferral,
  DeleteReferral,
  updateReferralStatus,
  UpdateReferral,
} from "../../../apis/Settings";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

export const columns = [
  {
    name: "Sr.No",
    selector: (row) => row.index,
    sortable: true,
  },
  {
    name: "Referral Threshold",
    selector: (row) => row.referral_threshold,
    sortable: true,
  },
  {
    name: "Free Laundry Amount",
    selector: (row) => row.free_laundry_amount,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: "ACTION",
    selector: (row) => row.action,
    sortable: false,
    width: "160px",
  },
];

const Referral = () => {
  const [data, setData] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(data?.status === "1");

  const [referral, setReferral] = useState({
   
  });

  const [editReferral, setEditReferral] = useState({
   
  });

  const [id, setId] = useState();
  const [isBtn, setIsBtn] = useState(false);

  async function getData() {
    const result = await GetReferral();
    setData(result?.data[0]);
    console.log(result?.data[0]);
  }

  const toggle = async (id, status) => {
    if (id) {
      let response = await updateReferralStatus(id, status);
      if (response?.status) {
        toast.success(response?.message);
        getData();
      } else {
        toast.error(response?.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBtn(true);
    console.log(referral);
    // if (!referral?.referral_threshold || !referral?.free_laundry_amount) {
    //   toast.error("All fields are required.");
    //   setIsBtn(false);
    //   return;
    // } else {
    let response;
    if (data?.id) {
   
      response = await UpdateReferral(
        data?.id,
        referral.referral_threshold ?? data?.referral_threshold,
        referral.free_laundry_amount ?? data?.free_laundry_amount,
        referral.status ?? data?.status
      );
    } else {
      response = await AddReferral(
        referral.referral_threshold,
        referral.free_laundry_amount,
        referral.status ?? '1'
      );
    }

    setIsBtn(false);
    if (response?.status) {
      toast.success(response?.message);
      // closeModal();
      getData();
    } else {
      toast.error(response?.message);
    }
    // }
  };

  const handleCheckboxChange = (e) => {
    const newStatus = e.target.checked ? "1" : "0";
    setIsChecked(e.target.checked);
    setReferral({
      ...referral,
      status: e.target.checked,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="right-nav admin-county py-4 px-2" id="admin-county">
      <div className="modal-inner-content">
        <h3>Referral Settings</h3>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group my-3">
              <label>
                {" "}
                Referral Threshold <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={data?.referral_threshold}
                placeholder="Set how many referrals are needed to earn free laundry"
                onChange={(e) =>
                  setReferral({
                    ...referral,
                    referral_threshold: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group mb-3">
              <label>
                {" "}
                Free Laundry Amount <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                defaultValue={data?.free_laundry_amount}
                placeholder=" Number of pounds of free laundry customers receive"
                onChange={(e) =>
                  setReferral({
                    ...referral,
                    free_laundry_amount: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group mb-3 d-flex justify-content-start">
              <div className="me-2 my-auto fw-bold">Status: </div>
              <div
                className="toggling form-check form-switch"
                // onClick={(e) =>
                //   setReferral({
                //     ...referral,
                //     status: !referral.status,
                //   })
                // }
              >
                {/* <input
                  className="form-check-input form-check-input-colored"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                /> */}

                <select
                  defaultValue={referral.status ?? data?.status}
                  onChange={(e) =>
                    setReferral({
                      ...referral,
                      status: e.target.value, 
                    })
                  }
                  className="form-select"
                >
                  <option value="1">ON</option>
                  <option value="0">OFF</option>
                </select>

                {/* <input type="checkbox"  /> */}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-lg btn-success"
              disabled={isBtn}
            >
              Save
              {isBtn ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Referral;
