import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  HTTPURL,
  APPID,
  APPLOCATION,
  SQUARESCRIPT,
} from "../../../constants/Matcher";
import { MyCalendar } from "../../common/MyCalendar";
import { Radio } from "../../common/Radio";

import Select from "react-select";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "../../../styles/PlaceOrder.css";
import { v4 as uuidv4 } from "uuid";

const NewBooking = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [user, setUser] = useState([]);
  const [categories, setCategories] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [pickupTime, setPickupTime] = useState("");
  const [instructions, setInstructions] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [repeatOptions, setRepeatOptions] = useState([
    { label: "One time order (Don't repeat weekly, bi weekly .. )", value: 0 },
    { label: "Weekly (Repeat every week for selected days)", value: 1 },
    { label: "Bi weekly (Repeat after 14 days for selected days)", value: 2 },
    { label: "Tri weekly (Repeat after 21 days for selected days)", value: 3 },
    { label: "Monthly (Repeat after 28 days  for selected days)", value: 4 },
  ]);
  const [daysChecked, setDaysChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [category, setCategory] = useState({});
  const [delivery, setDelivery] = useState(null);

  const [repeatOption, setRepeatOption] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [todayPickup, setTodayPickup] = useState(true);
  const [isBtn, setIsBtn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [idempotencykey, setIdempotencykey] = useState("");

  //  const [creditQuantity, setCreditQuantity] = useState(0);
  const [price, setPrice] = useState(null);
  const [quantitySelected, setQuantitySelected] = useState(null);
  const [credit, setCredit] = useState(0);

  const [commonState, setCommonState] = useState({
    pageSlideActive: "first",
    // pageSlideActive: "second",
  });

  const [cards, setCards] = useState();
  const [card, setCard] = useState(null);
  const [card_status_temp, setCard_status_temp] = useState();
  const [isChecked, setIsChecked] = useState(false);
  //  const [firstPickupDate, setFirstPickupDate] = useState(false);
  const [orderCustomerInstructions, setOrderCustomerInstructions] =
    useState("");
  const [privacyAndTerms, setPrivacyAndTerms] = useState(false);

  let today = new Date();
  let next_month = new Date(today.getFullYear(), today.getMonth() + 1, 1);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    setIdempotencykey(uuidv4());
    if (user?.id > 0) {
      if (user.pickup_zip) {
        getCategories(user.pickup_zip);
        getHolidays(user.pickup_zip);
        getCards();
        const script = document.createElement("script");
        script.src = SQUARESCRIPT;
        script.async = true;
        script.onload = () => setLoad();
        document.body.appendChild(script);
      } else {
        toast.error("Please first save user address");
      }
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    //     document.getElementById("root")?.scrollIntoView(true);
    if (category?.id && user?.pickup_zip) {
      getDeliveries(user.pickup_zip, category.id, category.unit);
    }
  }, [user, category]);

  useEffect(() => {
    setPrices();
  }, [quantity, selectedValues]);

  const getUser = () => {
    axios
      .get(HTTPURL + "/api/get-User/" + params.id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        // console.log(response?.data);
        if (response?.data?.status) {
          setUser(response?.data?.data);
        } else {
          toast.error(response?.data?.message);
        }
      });
  };

  const getCards = () => {
    axios
      .get(HTTPURL + "/api/get-cards/" + params.id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response?.data?.status) {
          setCards(response?.data?.CardList);
          if (response?.data?.CardList.length > 0) {
            // console.log(response?.data?.CardList[0].cardId);
            setCard(response?.data?.CardList[0].cardId);
          } else {
            //  console.log(response?.data?.CardList);
            setCard(0);
          }
        } else {
          toast.error(response?.data?.message);
        }
      });
  };

  async function getHolidays(pickup_zip) {
    axios
      .get(HTTPURL + "/api/get-holidays/" + pickup_zip, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response?.data?.status && response?.data?.data) {
          let res = [];
          response?.data?.data.forEach(function (e) {
            res.push(e.date);
          });
          setHolidays(res);
        } else {
          toast.error(response?.data?.message);
        }
      });
  }

  async function getCategories(pickup_zip) {
    axios
      .get(HTTPURL + "/api/get-categories/" + pickup_zip, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        console.log(response);
        if (response?.data?.status && response?.data?.data) {
          const result = response?.data;
          // console.log(result.data);
          setCategories(result.data);
          let category_id = result.data[0]?.id;

          if (user?.category_id) {
            category_id = user?.category_id;
          }
          let category = result.data.find((x) => x.id === category_id);

          if (!category) {
            category = result.data[0];
          }

          setQuantity(0);
          setCategory(category);
        } else {
          toast.error(response?.data?.message);
        }
      });
  }

  async function getDeliveries(pickup_zip, category_id, category_unit) {
    axios
      .get(HTTPURL + "/api/get-deliveries/" + pickup_zip + "/" + category_id, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then(function (response) {
        if (response?.data?.status && response?.data?.data) {
          const result = response?.data;
          let unit = "/lb";
          if (category_unit == 1) unit = "/bag";

          let res = [];
          result?.data.forEach(function (e) {
            res.push({
              value: e.id,
              price: e.price,
              label:
                e.price_title +
                ": USD " +
                e.price +
                "/" +
                (category_unit === 1 ? "bag" : "lb") +
                " (Min. Order:" +
                e.min_order +
                (category_unit == 1 ? " bags" : " lbs") +
                ")",
              days_gap: e.days_gap,
              min_order: e.min_order,
              max_order: e.max_order,
            });
          });

          console.log(res);
          setDeliveries(res);
        } else {
          toast.error(response?.data?.message);
        }
      });
  }

  const setLoad = async () => {
    const initializeCard = async (payments) => {
      const card = await payments.card();
      await card.attach("#card-container");
      //setCardCheck(true);
      return card;
    };

    const payments = window.Square.payments(APPID, APPLOCATION);
    let newCard;
    try {
      newCard = await initializeCard(payments);
    } catch (e) {
      console.error("Initializing Card failed.", e);
      return;
    }

    async function tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      // console.log("rest");
      // console.log(tokenResult);
      if (tokenResult.status == "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
      }
    }

    document
      .getElementById("card-button")
      .addEventListener("click", async function (event) {
        setIsBtn(true);
        setIsError(false);
        const price = event.target.getAttribute("price");
        const credit = event.target.getAttribute("credit");
        const quantity = event.target.getAttribute("quantity");
        const instructions = event.target.getAttribute("instructions");
        const quantitySelected = event.target.getAttribute("quantitySelected");
        const price_id = event.target.getAttribute("price_id");

        let selectedValues = event.target.getAttribute("selectedvalues");
        selectedValues = [...new Set(selectedValues.split(","))]?.join(",");

        const card = event.target.getAttribute("card");
        const repeatOption = event.target.getAttribute("repeatoption");
        const idempotencykey = event.target.getAttribute("idempotencykey");
        const pickup_time = document.getElementById("pickup_time").value;

        if (
          !price ||
          credit <= 0 ||
          quantity <= 0 ||
          selectedValues.length <= 0
        ) {
          setIsError(true);
          setIsBtn(false);

          toast.error("Please fill all required fields.");
          document
            .getElementById("scroll-form")
            ?.scrollIntoView(true, { behavior: "smooth" });

          return false;
        } else if (!pickup_time) {
          setIsError(true);
          setIsBtn(false);

          toast.error("Please fill all required fields.");
          document
            .getElementById("scroll-dates")
            ?.scrollIntoView(true, { behavior: "smooth" });

          return false;
        }

        let daysChecked = [];
        if (document.getElementById("weekday-sun").checked) {
          daysChecked.push(0);
        }
        if (document.getElementById("weekday-mon").checked) {
          daysChecked.push(1);
        }
        if (document.getElementById("weekday-tue").checked) {
          daysChecked.push(2);
        }
        if (document.getElementById("weekday-wed").checked) {
          daysChecked.push(3);
        }
        if (document.getElementById("weekday-thu").checked) {
          daysChecked.push(4);
        }
        if (document.getElementById("weekday-fri").checked) {
          daysChecked.push(5);
        }
        if (document.getElementById("weekday-sat").checked) {
          daysChecked.push(6);
        }

        let isChecked = 0;
        if (document.getElementById("flexCheckDefault").checked) {
          isChecked = 1;
        }

        try {
          //  setIsBtn(true);
          let token;
          if (card == 0) {
            token = await tokenize(newCard);
          } else {
            isChecked = 0;
            token = card;
          }

          let data = {
            user_id: params.id,
            locationId: APPLOCATION,
            idempotencyKey: idempotencykey,
            sourceId: token,
            credit: credit,
            price: price,
            quantitySelected: quantitySelected,
            quantity: quantity,
            card_status: isChecked,
            selectedValues: selectedValues,
            repeatOption: repeatOption,
            daysChecked: daysChecked,
            pickup_time: pickup_time,
            instructions: instructions,
          };

          const tokenVal = localStorage.getItem("token");
          axios
            .post(HTTPURL + "/api/request-pickup/" + params.id, data, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${tokenVal}`,
              },
            })
            .then(function (response) {
              setIsBtn(false);
              if (response?.data?.status) {
                toast.success(
                  "('Pickup Request has been submitted successfully. Our agent will contact you before pickup."
                );
                navigate("/customers-list");
              } else {
                toast.error(response?.data?.message);
              }
            })
            .catch(function (error) {
              setIsBtn(false);
              toast.error(error.message);
            });

          // const paymentResults = await createPayment(token);
        } catch (e) {
          setIsBtn(false);
          console.error(e.message);
          toast.error("Payment is not done, please try later");
        }
      });
  };

  const getDateString = (d) => {
    return (
      d.getFullYear() +
      "-" +
      (d.getMonth() < 9 ? "0" : "") +
      (d.getMonth() + 1) +
      "-" +
      (d.getDate() < 10 ? "0" : "") +
      d.getDate()
    ); //prints expected format.
  };

  const clickDay = (e) => {
    let day = e.target.value;
    let is_checked = e.target.checked;
    setDaysChecked({ ...daysChecked, [day]: is_checked });

    //   console.log(daysChecked);
    let d = new Date();
    if (!todayPickup) {
      d.setDate(d.getDate() + 1);
    }

    while (d.getDay() != day) {
      d.setDate(d.getDate() + 1);
    }
    let next_month_last = new Date(
      d.getFullYear(),
      d.getMonth() + 2,
      0,
      "23",
      "59"
    );

    if (is_checked) {
      while (d <= next_month_last) {
        let dont_add = false;
        let days_jump = 63; //max jump for days 2 months
        if (repeatOption == 1) days_jump = 7;
        else if (repeatOption == 2) days_jump = 14;
        else if (repeatOption == 3) days_jump = 21;
        else if (repeatOption == 4) days_jump = 28;

        let mysql_date = getDateString(d);
        if (holidays.includes(mysql_date)) {
          dont_add = true;
        }
        if (dont_add === false) {
          setSelectedValues((selectedValues) => [
            ...selectedValues,
            mysql_date,
          ]);
        }

        d.setDate(d.getDate() + days_jump);
      }
    } else {
      let mysql_date_arr = [];
      while (d <= next_month_last) {
        let mysql_date = getDateString(d);
        mysql_date_arr.push(mysql_date);
        d.setDate(d.getDate() + 7);
      }

      setSelectedValues(
        selectedValues.filter((item) => !mysql_date_arr.includes(item))
      );
    }

    //    setSelectedValues([...new Set(...selectedValues.split(","))]?.join(","));

    //  document.getElementById("scroll-delivery")?.scrollIntoView(true, {behavior: 'smooth'});
    //  console.log(selectedValues);
  };

  const changeDelivery = (e) => {
    //  console.log(e);

    setDelivery(e);
    //   document.getElementById("scroll-pickup")?.scrollIntoView(true,{ behavior: 'smooth' });
  };

  const setPrices = async () => {
    let currentState = false;
    if (quantity && selectedValues.length > 0) {
      var p = delivery;

      if (p) {
        console.log(p);
        let sq = 0;

        if (category?.unit == 1 && p.min_order <= quantity) {
          sq = quantity;
        } else {
          if (category?.upfront_amount == 1) {
            //  if(p.max_order > 0){
            //     sq = p.max_order;
            //  }
            // else
            if (p.min_order > quantity) {
              sq = p.min_order;
            } else {
              sq = quantity;
            }
          } else if (p.min_order > quantity) {
            sq = p.min_order;
          } else {
            sq = quantity;
          }
          setIsChecked(true);
        }
        console.log(p);
        setPrice(p);
        setQuantitySelected(sq);

        // if (category?.unit == 1 && repeatOption == 0) {
        setCredit(p?.price * sq * selectedValues.length);
        // } else {

        //   setCredit(p?.price * sq);

        //}

        //       console.log("out3");

        //     console.log(selectedValues);

        let min_date = selectedValues.reduce((a, b) => (a <= b ? a : b));
        min_date = new Date(min_date);
        //  setFirstPickupDate(min_date.toDateString());
        //      setCommonState({ ...commonState, pageSlideActive: "second" });
        //      window.scrollTo({
        //        top: 0,
        //        behavior: "instant",
        //      });
      }
    }
  };

  async function disableCard(card_id) {
    // alert(card_id);

    try {
      const tokenVal = localStorage.getItem("token");

      axios
        .post(
          HTTPURL + "/api/remove-card/" + params.id,
          { card_id: card_id, user_id: params.id },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${tokenVal}`,
            },
          }
        )
        .then(function (response) {
          if (response?.data?.status) {
            toast.success("('Card removed successfully.");
            getCards();
          } else {
            // console.log(response?.data);
            toast.error(response?.data?.message);
          }
        })
        .catch(function (error) {
          toast.error(error.message);
        });

      // const paymentResults = await createPayment(token);
    } catch (e) {
      toast.error("Card cannot be deleted, please try later");
      console.error(e.message);
    }
  }

  return (
    <Fragment>
      <div className="Uf-registration place-order" id="scroll-top">
        <section className="form-sec grey-back pt-0 mt-0">
          <div className="form-inner container my-4 pt-0" id="scroll-form">
            <form>
              <div className="text-center">
                <h3>Pickup Request on behalf of {user?.name}</h3>
              </div>
              <div className="row mb-4 pb-2">
                <div className="col-12 mt-3 text-center">
                  {category?.id && (
                    <>
                      <Radio
                        width={"110px"}
                        onChange={(e) => {
                          setCategory(e);
                        }}
                        value={category?.id}
                        options={categories}
                      />

                      <div className="mt-2 prices text-center">
                        <ul>
                          {category?.price?.map((cp) => (
                            <li key={cp.price_title} className="bold-text">
                              {cp.price_title}: USD {cp.price}/
                              {category.unit == 1 ? "bag" : "lb"} ( Min. Order:{" "}
                              {cp.min_order}{" "}
                              {category.unit == 1 ? "bags" : "lbs"})
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="row mb-4 " id="scroll-delivery">
                <div
                  className={
                    !delivery && isError ? "col-12 text-error" : "d-none"
                  }
                >
                  <p className="text-danger">Please choose pricing package</p>
                </div>
                <div className="col-12  d-md-inline-flex align-items-center">
                  <label className="min-label">
                    Pricing Package<sup>*</sup>:&nbsp;&nbsp;
                  </label>
                  <Select
                    className="flex-grow-1 "
                    name="deliveryTimeSlot"
                    id="deliveryTimeSlot"
                    onChange={changeDelivery}
                    value={
                      deliveries?.find((o) => o.value == delivery?.value) || ""
                    }
                    options={deliveries}
                  />
                </div>
              </div>

              {category?.unit == 1 && (
                <>
                  <div className="row mb-4" id="scroll-quantity">
                    <div
                      className={
                        !quantity && isError ? "col-12 text-error" : "d-none"
                      }
                    >
                      <p className="text-danger">Please enter no of bags</p>
                    </div>
                    <div className="col-12 d-md-inline-flex align-items-center">
                      <label className="min-label">
                        No. of bags<sup>*</sup>:&nbsp;&nbsp;
                      </label>
                      <input
                        className="form-control mt-2"
                        type="text"
                        name="no_of_bags"
                        id="no_of_bags"
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {category?.unit == 2 && (
                <>
                  <div className="row mb-4" id="scroll-quantity">
                    <div
                      className={
                        !quantity && isError ? "col-12 text-error" : "d-none"
                      }
                    >
                      <p className="text-danger">Please enter approx Wt.</p>
                    </div>
                    <div className="col-12 d-md-inline-flex align-items-center">
                      <label className="min-label">
                        Estimated Wt.<sup>*</sup>:&nbsp;&nbsp;
                      </label>
                      <input
                        className="form-control mt-2"
                        type="text"
                        name="no_of_bags"
                        id="no_of_bags"
                        value={quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-4 pb-2" id="scroll-order-type">
                <div className="col-12  d-md-inline-flex align-items-center">
                  <label className="min-label">
                    Order Type<sup>*</sup>:&nbsp;&nbsp;
                  </label>
                  <Select
                    defaultValue={{
                      label:
                        "One time order (Don't repeat weekly, bi weekly ..",
                      value: 0,
                    }}
                    className="flex-grow-1"
                    name="repeatOrder"
                    id="repeatOrder"
                    onChange={(e) => {
                      setRepeatOption(e.value);
                      setSelectedValues([]);
                      setDaysChecked([
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                        false,
                      ]);
                      document
                        .getElementById("scroll-delivery")
                        ?.scrollIntoView(true, { behavior: "smooth" });
                      //     document.getElementById("scroll-top")?.scrollIntoView(true,{ behavior: 'smooth'});
                    }}
                    options={repeatOptions}
                  />
                </div>
              </div>

              <div className="row mb-4 " id="scroll-days">
                <div className="col-12 d-md-inline-flex align-items-center">
                  <label className="min-label">
                    Pickup Days<sup>*</sup>:&nbsp;&nbsp;
                  </label>
                  <div className="weekDays-selector">
                    <label>
                      <input
                        type="checkbox"
                        id="weekday-sun"
                        className="weekday"
                        value="0"
                        onChange={clickDay}
                        checked={daysChecked[0]}
                      />{" "}
                      Sun
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="weekday-mon"
                        className="weekday"
                        value="1"
                        onChange={clickDay}
                        checked={daysChecked[1]}
                      />{" "}
                      Mon
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="weekday-tue"
                        className="weekday"
                        value="2"
                        onChange={clickDay}
                        checked={daysChecked[2]}
                      />{" "}
                      Tue
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="weekday-wed"
                        className="weekday"
                        value="3"
                        onChange={clickDay}
                        checked={daysChecked[3]}
                      />{" "}
                      Wed
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="weekday-thu"
                        className="weekday"
                        value="4"
                        onChange={clickDay}
                        checked={daysChecked[4]}
                      />{" "}
                      Thu
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="weekday-fri"
                        className="weekday"
                        value="5"
                        onChange={clickDay}
                        checked={daysChecked[5]}
                      />{" "}
                      Fri
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        id="weekday-sat"
                        className="weekday"
                        value="6"
                        onChange={clickDay}
                        checked={daysChecked[6]}
                      />{" "}
                      Sat
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mb-4 pb-2" id="scroll-dates">
                <div
                  className={
                    selectedValues.length == 0 && isError
                      ? "col-12 text-error"
                      : "d-none"
                  }
                >
                  <p className="text-danger">Please choose Pickup Days</p>
                </div>
                <div className="col-md-12 pt-2 text-center double">
                  <MyCalendar
                    months={[
                      { month: today.getMonth(), year: today.getFullYear() },
                      {
                        month: next_month.getMonth(),
                        year: next_month.getFullYear(),
                      },
                    ]}
                    holidays={holidays}
                    selectedValues={selectedValues}
                    delivery={delivery}
                    todayPickup={todayPickup}
                    onChange={(e) => {
                      if (selectedValues.includes(e.date)) {
                        setSelectedValues(
                          selectedValues.filter((item) => item !== e.date)
                        );
                      } else {
                        setSelectedValues((selectedValues) => [
                          ...selectedValues,
                          e.date,
                        ]);
                      }
                      //  setSelectedValues([...new Set(...selectedValues.split(","))]?.join(","));

                      document
                        .getElementById("scroll-delivery")
                        ?.scrollIntoView(true, { behavior: "smooth" });
                    }}
                  />
                </div>
              </div>

              <div className="row  ">
                <div className="col-12">
                  <div
                    className={
                      !pickupTime && isError ? "col-12 text-error" : "d-none"
                    }
                  >
                    <p className="text-danger">
                      Please enter laundry pickup time.
                    </p>
                  </div>

                  <label className="w-100">
                    What time will your laundry be ready for pickup?<sup>*</sup>
                    :&nbsp;&nbsp;
                  </label>
                  <input
                    placeholder="e.g. in the evening around 6 o'clock"
                    className="form-control"
                    type="text"
                    name="pickup_time"
                    id="pickup_time"
                    value={pickupTime}
                    onChange={(e) => {
                      setPickupTime(e.target.value);
                    }}
                  />
                </div>

                <div className="col-12 ">
                      <label className="pb-0">
                        Folder Instructions
                      </label>
                      {/* <input
                        className="form-control "
                        type="text"
                        name="instruction"
                        id="instruction"
                        value={instructions ?? ""}
                        onChange={(e) => {
                          setInstructions(e.target.value);
                        }}
                      /> */}
                      <textarea id="instructions"  rows="4" cols="50"  className="form-control text-start"   name="instruction"
                        
                        value={instructions ?? ""}
                        onChange={(e) => {
                          setInstructions(e.target.value);
                        }} placeholder="Enter your instructions here"></textarea>
                    </div>
              </div>

              <div className="row mb-2" id="selection-card">
                <div className="col-12 mt-3">
                  {cards &&
                    cards?.length > 0 &&
                    cards.map((c, index) => (
                      <div
                        className="shadow-lg p-3 mb-4 bg-body rounded card-token"
                        key={c?.cardId}
                      >
                        <input
                          className=""
                          type="radio"
                          name="customerCard"
                          value={c?.cardId}
                          onChange={(e) => setCard(e.target.value)}
                          checked={card == c?.cardId ? true : false}
                        />
                        <label
                          onClick={(e) => setCard(c?.cardId)}
                          className="card-token-label"
                          htmlFor={c?.cardId}
                        >
                          Add credit with {c?.brand} ending in {c?.last4}
                        </label>

                        <label
                          className="card-token-trash float-end"
                          onClick={(e) => {
                            disableCard(c?.cardId);
                          }}
                        >
                          <i className="fa fa-trash-can "></i>
                        </label>
                      </div>
                    ))}

                  <div
                    className="shadow-lg p-3 mb-4 bg-body rounded card-token"
                    key={card?.cardId}
                  >
                    <input
                      className=""
                      type="radio"
                      name="customerCard"
                      value={0}
                      onChange={(e) => setCard(0)}
                      checked={card == 0 ? true : false}
                    />
                    <label
                      className="card-token-label mb-4"
                      onClick={(e) => setCard(0)}
                    >
                      Add new billing information to add credit
                    </label>

                    <div
                      className={card != 0 ? "d-none" : undefined}
                      id="card-container"
                    ></div>
                    <div className={card != 0 ? "d-none" : "set-chkbox"}>
                      <div className="form-check">
                        <input
                          disabled={category?.unit == 2 || repeatOption > 0}
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          checked={isChecked}
                          onChange={(e) => setIsChecked(e.target.checked)}
                        />
                        <label
                          className="card-token-label"
                          htmlFor="flexCheckDefault"
                        >
                          Save card details for future payment
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row  pb-2">
                <div className="col-12 ">
                  {category?.unit == 1 && (
                    <>
                      {selectedValues?.length > 0 && (
                        <div className="row mb-2 ">
                          <div className="col-4 ">
                            <label className="order-label">Pickup Days: </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              {selectedValues.toString()}
                            </label>
                          </div>
                        </div>
                      )}
                      {quantity > 0 && (
                        <div className="row mb-2 ">
                          <div className="col-4 ">
                            <label className="order-label">
                              No of bags/ pickup:{" "}
                            </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">{quantity}</label>
                          </div>
                        </div>
                      )}
                      {price?.price > 0 && (
                        <div className="row mb-2 ">
                          <div className="col-4 ">
                            <label className="order-label">Price/ bag: </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              USD {price?.price}/bag ( USD{" "}
                              {price?.price * quantitySelected} /pickup ){" "}
                            </label>
                          </div>
                        </div>
                      )}
                      {credit > 0 && (
                        <div className="row mb-2 ">
                          <div className="col-4 ">
                            <label className="order-label">
                              Total Amount for ({quantitySelected} bags):{" "}
                            </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              USD {credit} for {selectedValues.length} pickups
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {category?.unit == 2 && (
                    <>
                      {selectedValues?.length > 0 && (
                        <div className="row ">
                          <div className="col-4 ">
                            <label className="order-label">Pickup Date: </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              {selectedValues.toString()}
                            </label>
                          </div>
                        </div>
                      )}
                      {quantity > 0 && (
                        <div className="row ">
                          <div className="col-4 ">
                            <label className="order-label">
                              Estimated Wt.:{" "}
                            </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              {quantity} lbs (Min. {price?.min_order} lbs)
                            </label>
                          </div>
                        </div>
                      )}
                      {price?.price > 0 && (
                        <div className="row ">
                          <div className="col-4 ">
                            <label className="order-label">Price: </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              USD {price?.price}/lb (USD{" "}
                              {price?.price * quantitySelected} /pickup for{" "}
                              {quantitySelected} lbs){" "}
                            </label>
                          </div>
                        </div>
                      )}
                      {credit > 0 && (
                        <div className="row ">
                          <div className="col-4 ">
                            <label className="order-label">
                              Credit Required:{" "}
                            </label>
                          </div>
                          <div className="col-8 ">
                            <label className="order-value">
                              USD {credit} for {selectedValues?.length} pickups
                            </label>
                          </div>
                          <div className="col-12">
                            <span className="text-danger">
                              Actual cost will be deducted from your credit
                              after being wighted. Balance will be added to your
                              credit wallet.
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="combo-btn">
                    <div className="sap-blue-btn text-center">
                      <button
                        className="btn btn-lg btn-success"
                        price={JSON.stringify(price)}
                        credit={credit}
                        quantity={quantity}
                        instructions={instructions}
                        quantityselected={quantitySelected}
                        selectedvalues={selectedValues}
                        card={card}
                        repeatoption={repeatOption}
                        idempotencykey={idempotencykey}
                        id="card-button"
                        type="button"
                        disabled={isBtn}
                      >
                        Pay ${credit} & confirm pickup request
                        {isBtn ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>

                  <div id="payment-status-container"></div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
export default NewBooking;
