import React, { useState } from "react";
import DataTable from "react-data-table-component";

import "react-data-table-component-extensions/dist/index.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderDataTable = ({ columns, data , onDropdownChange=null , showfilter=null}) => {
  const [searchData, setSeacrhData] = useState("");
  const [filterData, setFilterData] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const onSearch = (value) => {
    setSeacrhData(value);
  
    const searchRes = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    
    setFilterData(searchRes);
  };
  const customStyles = {
    headCells: {
      style: {
        display: "none",
      },
    },
    rows: {
      style: {
        display: "flex",
        flexWrap: "wrap", // Allow wrapping of cards onto the next row
        flexDirection: "column",
        margin: "10px 0",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.28)",
        width: "30%",
        borderRadius: "15px",
        padding: "10px 0",
      },
    },
    cells: {
      style: {
        margin: "10px", // Spacing between cards
        // padding: '10px',            // Card padding

        minWidth: "250px",
      },
    },
    expanderCell: {
      style: {
        // backgroundColor: "#a82928",
        // color: "white",
      },
    },
    expanderButton: {
      style: {
        backgroundColor: "#eee",
        borderRadius: "2px",
        transition: "0.25s",
        height: "100%",
        width: "100%",
        "&:hover:enabled": {
          cursor: "pointer",
        },
        "&:hover:not(:disabled)": {
          cursor: "pointer",
          backgroundColor: "#a82928",
          color: "#fff",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#a82928",
          color: "#fff",
        },
        svg: {
          margin: "auto",
        },
      },
    },
    expanderRow: {
      style: {
        color: "#000",
        backgroundColor: "#eee",
        padding: "10px 70px",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.color == 1,
      style: {
        backgroundColor: "#488102",
        color: "#fff",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#c2d5aa",
          color: "#333",
        },
      },
    },
  ];

  const ExpandedComponent = ({ data }) => {
    return (
      <>
        <div className="row">
          <div class="col-6">
            <p className="mb-1">
              <b>Name: </b>
              {data.name}{" "}
            </p>
            <p className="mb-1">
              <b>Email: </b>
              {data.email}
            </p>
            <p className="mb-1">
              <b>Mobile: </b>
              <a href="callto:{{data.mobile}}">{data.mobile}</a>
            </p>
            <p className="mb-1">
              <b>Pickup Address: </b>
              {data.pickup_address}
            </p>
            <p className="mb-1">
              <b>Delivery Address: </b>
              {data.delivery_address}
            </p>
            <p className="mb-1">
              <b>Actual Bags/Wt.:: </b>
              {data.total_loads}
            </p>
          </div>
          <div class="col-6">
            <p className="mb-1">
              <b>Driver: </b>
              {data.driver}
            </p>
            <p className="mb-1">
              <b>Folder: </b>
              {data.folder}
            </p>
            <p className="mb-1">
              <b>Driver Instructions: </b>
              {data.driver_instructions}
            </p>
            <p className="mb-1">
              <b>Folder Instructions: </b>
              {data.folder_instructions}
            </p>
            <p className="mb-1">
              <b>Bin #: </b>
              {data.bin}
            </p>
            <p className="mb-1">
              <b>Estimated Bags/Wt.: </b>
              {data.estimated_loads}
            </p>
          </div>
          <div class="col-12">{data.action}</div>
        </div>
      </>
    );
  };

  const filterBooking=(e)=>{
    const selectedValue = e.target.value;

    onDropdownChange(selectedValue);
  }

  return (
    <>
    <div className={showfilter ?  "row filter-main" : 'row justify-content-end' }>

    {showfilter && 
    <div className="filter-drop w-50 col-md-6  ">
      <select className="form-control w-50" onChange={filterBooking}> 
          <option value="latest">Latest</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="tomorrow">Tomorrow</option>
          <option value="to_be_delivered">To Be Delivered</option>
          <option value="undelivered">Undelivered</option>
        </select>
    </div>
    }
      <div className="searc-bar col-md-6"> 
        
        <input
          type="search"
          className="form-control "
          placeholder="Search here..."
          onChange={(e) => onSearch(e.target.value)}
        />
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
            console.log(update[1]);
            if (update[0] && update[1]) {
              setSeacrhData(true);
              const searchRes = data.filter((item) => {
                return (
                  new Date(item.pickupdate).getTime() >= update[0].getTime() &&
                  new Date(item.pickupdate + " 00:00").getTime() <=
                    update[1].getTime()
                );
              });
              setFilterData(searchRes);
            } else {
              setSeacrhData(false);
              setFilterData(data);
            }
          }}
          isClearable={true}
          placeholderText="Pickup Date Range"
        />
      </div>
      </div>
      <div className="card_table">
        
        <DataTable
          columns={columns}
          data={searchData ? filterData : data}
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          paginationRowsPerPageOptions={[50, 100, 250]}
          paginationPerPage={100}
          highlightOnHover
          customStyles={customStyles}
          //   expandableRows
          expandableRowsComponent={ExpandedComponent}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
};

export default OrderDataTable;
