import React, { useState, useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { ROUTES } from "./../driver/MenuItems";

const AdminLayout = () => {
  const [toggle, setToggle] = useState(false);
  const [routes, setRoutes] = useState(
    ROUTES.filter((sidebarnavItem) => sidebarnavItem)
  );
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    const currentPath = location.pathname;
    setCurrentPath(currentPath);
  }, [location]);
  const toggleClass = () => {
    setToggle(!toggle);
  };

  return (
    <div className="dash-backdrop">
      <nav class="navbar navbar-expand-lg navbar-light bg-light container-fluid">
        <div class="container-fluid">
          <a class="navbar-brand" href="https://weclea.com">
            <img src="https://weclea.com/wp-content/uploads/2024/02/cropped-Weclea-Logo-135x48.png" />
          </a>
          <button
            class="navbar-toggler mx-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li class="nav-item">
                <a
                  className={`nav-link ${
                    currentPath == "/driver-dashboard" ? "active" : ""
                  }`}
                  aria-current="page"
                  href="/driver-dashboard"
                >
                  Dashboard
                </a>
              </li>
              {routes.map((route, index) => (
                <>
                  {route.submenu.length ? (
                    <li key={route?.path} class="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href={route?.path}
                        id={route?.path}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {route?.title}
                      </a>
                      <ul class="dropdown-menu" aria-labelledby={route?.path}>
                        {route.submenu.map((sub) => (
                          <li key={route?.sub + "sub"}>
                            <a
                              class={`dropdown-item ${
                                currentPath == sub?.path ? "active" : ""
                              }`}
                              href={sub?.path}
                            >
                              {sub?.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ) : (
                    <li key={route?.path + "_2"} className="nav-item">
                      <a
                        className={`nav-link ${
                          currentPath == route?.path ? "active" : ""
                        }`}
                        href={route?.path}
                      >
                        {route?.title}
                      </a>
                    </li>
                  )}
                </>
              ))}
            </ul>
            <form class="d-flex">
              <li class="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownProfile"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {localStorage.getItem("email")}
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownProfile"
                >
                  <li>
                    <a
                      class={`dropdown-item ${
                        currentPath == "" ? "active" : ""
                      }`}
                      href="/driver-my-profile"
                    >
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/logoutDriver">
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </form>
          </div>
        </div>
      </nav>

      <div id="layoutSidenav_content">
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};
export default AdminLayout;
