import React, { useState } from "react";
import { HTTPURL } from "../constants/Matcher";

const BookingFlow = ({ bookingStatusDetail }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="tab-content" id="pills-tabContent">
      {["pickup", "attendant_checking", "Washed", "dry", "folded", "packing"].map(
        (status) => (
          <div
            key={status}
            className={`tab-pane fade ${status === "pickup" ? "show active" : ""}`}
            id={`pills-${status}`}
            role="tabpanel"
            aria-labelledby={`pills-${status}-tab`}
          >
            {bookingStatusDetail?.some((item) => item.status === status.toLowerCase()) ? (
              bookingStatusDetail
                .filter((item) => item.status === status.toLowerCase())
                .map((item) => (
                  <div key={item.id} className="card my-3">
                    <div className="card-body">
                      <h5 className="card-title text-capitalize">
                        Status: {item.status}
                      </h5>
                      {item.image && (
                        <div className="mb-2">
                          <strong>Image:</strong>
                          <img
                            src={`${HTTPURL}/uploads/${item.image}`}
                            alt=""
                            className="img-fluid mt-2"
                            onClick={() => handleImageClick(item.image)}
                            style={{ cursor: "pointer", borderRadius: "5px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
                          />
                        </div>
                      )}
                      {item.weight && (
                        <p className="mb-1">
                          <strong>Weight:</strong> {item.weight}
                        </p>
                      )}
                      {item.bag_no && (
                        <p className="mb-1">
                          <strong>No of Bags:</strong> {item.bag_no}
                        </p>
                      )}
                      {item.machine_no && (
                        <p className="mb-1">
                          <strong>Machine No:</strong> {item.machine_no}
                        </p>
                      )}
                      {item.washing_image && (
                        <p className="mb-1">
                          <strong>Washing Image:</strong> <img
                            src={`${HTTPURL}/uploads/${item.washing_image}`}
                            alt=""
                            className="img-fluid mt-2"
                            onClick={() => handleImageClick(item.washing_image)}
                            style={{ cursor: "pointer", borderRadius: "5px", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
                          /> 
                        </p>
                      )}
                      {item.created_at && (
                        <p className="mb-1">
                          <strong>Date:</strong>{" "}
                          {new Date(item.created_at).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </p>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <div className="text-muted">No Record</div>
            )}
          </div>
        )
      )}

      {/* Modal for displaying the image */}
      {selectedImage && (
        <div className="modal show" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Image Preview</h5>
                <button
                  type="button"
                  className="btn-close text-light"
                  onClick={closeModal}
                  style={{ fontSize: "1.2rem", padding: "0.5rem" }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body text-center">
                <img
                  src={`${HTTPURL}/uploads/${selectedImage}`}
                  alt="Selected"
                  className="img-fluid"
                  style={{ borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-lg"
                  onClick={closeModal}
                  style={{
                    backgroundColor: "#343a40",
                    borderColor: "#343a40",
                    borderRadius: "5px",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingFlow;
