import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";
import axios from "axios";

export const GetOrderCustomerInstructions = async () => {
  try {
    const response = await axios.get(
      HTTPURL + `/api/get-order-customer-instructions`,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("token")
        }
      }
    );

    return response?.data;
  } catch (error) {}
};

export const UpdateCustomerInstructions = async (data) => {
  try {
    const response = await axios.post(
      HTTPURL + `/api/update-customer-instructions`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("token")
        }
      }
    );

    return response?.data;
  } catch (error) {}
};

export const GetPrivacyAndTerms = async () => {
  try {
    const response = await axios.get(HTTPURL + `/api/get-privacy-and-terms`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("token")
      }
    });

    return response?.data;
  } catch (error) {}
};

export const UpdatePrivacyAndTerms = async (data) => {
  try {
    const response = await axios.post(
      HTTPURL + `/api/update-privacy-and-terms`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem("token")
        }
      }
    );

    return response?.data;
  } catch (error) {}
};
