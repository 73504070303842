import axios from "axios";
import React, { useEffect, useState } from "react";
import { HTTPURL, HTTPURLFRONT } from "../../../constants/Matcher";

import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import {
  get_emailTemplate_detail,
  sendNotification,
  updateInstruction,
  updateUserStatus,
} from "./../../../apis/Items";
import { GetCategories } from "../../../apis/Settings";
import { GetAllCustomers } from "./../../../apis/Users";
import Select from "react-select";

import CustomersDataTable from "./CustomersDataTable";
import { Modal, ModalHeader } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import dateFormat from "date-and-time";

export const columns = [
  {
    name: " ID",
    selector: (row) => row.id_link,
    sortable: true,
    width: "80px",
  },
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Contact Number",
    selector: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "Category",
    width: "120px",
    selector: (row) => row.category_title,
    sortable: true,
  },
  {
    name: "Credit",
    selector: (row) => row.credit,
    sortable: true,
    width: "120px",
  },
  {
    name: "STATUS",
    selector: (row) => row.status,
    width: "90px",
  },
  {
    name: "Subscription",
    selector: (row) => row.subscription,
    sortable: true,
    // width: "90px",
  },
  {
    name: "Actions",
    selector: (row) => row.action,
    sortable: true,
    width: "250px",
  },
];
const Customers = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editCustomer, setEditCustomer] = useState([]);
  const [isBtn, setIsBtn] = useState(false);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [instructions, setInstructions] = useState({
    id: 0,
    delivery_instruction: "",
    driver_instructions: "",
  });

  async function getCategories() {
    const result = await GetCategories();
    console.log(result);
    if (result?.data) {
      setCategories(result.data);
    }
  }

  async function get_data() {
    try {
      const result = await GetAllCustomers();
      //console.log(result);
      setUsers(result?.data);
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          folder_instructions,
          driver_instructions,
          status,
          credit,
          name,
          email,
          mobile,
          category_title,
          delivery_address,
          pickup_address,
        } = elem;

        return {
          id: id,
          id_link: (
            <Link title="View Customer Details" to={"/customer-details/" + id}>
              {id}
            </Link>
          ),
          name: name,
          email: email,
          mobile: mobile,
          category_title: category_title,
          folder_instructions: folder_instructions,
          driver_instructions: driver_instructions,
          delivery_address: delivery_address,
          pickup_address: pickup_address,
          credit: "$" + credit,
          status: (
            <div
              className="toggling form-check form-switch"
              title="Click here to activate or deactivate"
              onClick={() => toggle(id, status)}
            >
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                checked={status ? true : false}
              />
            </div>
          ),
          subscription: (
            <>
             { elem?.cron_user_ids ?	
              <button
                className="btn btn-md btn-link me-1 text-dark"
                onClick={() => {
                  DeleteSubscriptionHandler(elem);
                }}
                title="Click here to delete subscription"
              >
<i class="fa-solid fa-trash"></i>
              </button> :   "No Subscription"}
            </>
          ),
          action: (
            <>
              <button
                className="btn btn-md btn-link me-1 text-dark"
                onClick={() => {
                  openEditCustomerModal(elem);
                }}
                title="Click here to edit the customer's details"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
              <button
                className="btn btn-md btn-link me-1 text-dark"
                onClick={() => visitTo(id)}
                title="Click here to login the user's account"
              >
                <i class="fa-solid fa-right-to-bracket"></i>
              </button>
              <Link
                className="btn btn-md btn-link me-1 text-dark"
                title="New Laundry Booking"
                to={"/new-booking/" + id}
              >
                <i class="fa-solid fa-truck"></i>
              </Link>
              <Link
                className="btn btn-md btn-link me-1 text-dark"
                title="Add Credit"
                to={"/add-credit/" + id}
              >
                <i class="fa-solid fa-usd"></i>
              </Link>
              <Link
                className="btn btn-md btn-link me-1 text-dark"
                title="Remove Credit"
                to={"/remove-credit/" + id}
              >
                <i class="fa-solid fa-strikethrough"></i>
              </Link>
            </>
          ),
        };
      });

      console.log(arrayData);
      setData(arrayData, setShowResults(true));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCategories();
    get_data();
  }, []);

  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
  };

  const visitTo = (id) => {
    let token = localStorage.getItem("token");
    window.open(HTTPURLFRONT + "/admin-login/" + id + "/" + token, "_blank");
  };
  const openDeliveryModal = (id, delivery_instruction, driver_instructions) => {
    console.log("folder_instructions", delivery_instruction);
    setInstructions({
      ...instructions,
      id: id,
      delivery_instruction: delivery_instruction,
      driver_instructions: driver_instructions,
    });
  };

  const toggle = async (id, status) => {
    if (id) {
      let result = await updateUserStatus(id, status);
      if (result.status) {
        toast.success(result.message);
        get_data();
      } else {
        toast.error(result.message);
      }
    }
  };

  const openEditCustomerModal = async (e) => {
    const edit_category = categories.find((x) => x.id == e.category_id);
    //console.log(edit_category);
    setEditCustomer({
      ...editCustomer,
      id: e.id,
      profile_image: {
        preview: e.profile_image
          ? HTTPURL + "/uploads/customers/" + e.profile_image
          : "",
        data: "",
      },
      name: e.name,
      email: e.email,
      mobile: e.mobile,
      category: edit_category,
      status: e.status,
      profile_image_old: e.profile_image,
    });

    setEditModal(true);
  };

  const saveEditCustomer = async () => {
    try {
      let content_type = "multipart/form-data";
      console.log(editCustomer);
      const form = new FormData();
      form.append("id", editCustomer?.id);
      form.append("profile_image", editCustomer?.profile_image.data);
      form.append("name", editCustomer?.name);
      form.append("email", editCustomer?.email);
      form.append("mobile", editCustomer?.mobile);
      form.append("category", editCustomer?.category?.id);
      form.append("status", editCustomer?.status ? 1 : 0);

      form.append("profile_image_old", editCustomer?.profile_image_old);

      const response = await axios.post(
        HTTPURL + `/api/update-customer`,
        form,
        {
          headers: {
            "Content-Type": content_type,
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      const result = response?.data;
      if (result.status) {
        toast.success("Customer Updated Successfully");
        get_data();
        setEditModal(false);
      } else {
        toast.error(result.message);
      }
    } catch (error) {}
  };


  const DeleteSubscriptionHandler = (e) => {
   
    let form = { user_id: e?.id, cron_id: e?.cron_user_ids };
    const confirmDelete = window.confirm(
      "Are You Sure You Want To Cancel Subscription Of This User?"
    );
    if (confirmDelete) {
      axios
        .post(HTTPURL + `/api/cancel-subscription-user`, form, {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setIsLoader(false);

          if (response?.data?.status) {
            toast.success(response?.data?.message);

            get_data();
          } else {
            toast.error(
              "Error in QR Printing. Reason: " + response?.data?.messages
            );
          }
        })
        .catch((error) => {
          setIsLoader(false);
          toast.error("Error . Reason: " + error);
        });
    } else {
      // User canceled the deletion
      toast.info("Deletion canceled.");
    }
  };

  return (
    <>
      <div className="right-nav py-4 px-2" id="dash-event">
        <h3>Customers List</h3>
        {showResults ? (
          <>
            <CustomersDataTable columns={columns} data={data} />
          </>
        ) : (
          <DataLoader />
        )}
      </div>

      <Modal
        animation={false}
        show={editModal}
        onHide={() => {
          setEditModal(false);
        }}
        keyboard={false}
        backdrop="static"
        id="edit-driver-modal"
        size="lg"
      >
        <Modal.Body>
          <div className="modal-inner-content">
            <h3>Edit Customer</h3>
            <form className="form-horizontal">
              <div className="row">
                <div className="col-12 form-group mt-3">
                  <label>
                    Profile Photo:<span className="text-danger">*</span>
                  </label>
                  {editCustomer?.profile_image?.preview && (
                    <img
                      className="mb-1 me-1"
                      src={editCustomer.profile_image.preview}
                      width="100"
                      height="100"
                    />
                  )}

                  <input
                    className="form-control"
                    type="file"
                    name="file"
                    onChange={(e) => {
                      const img = {
                        preview: URL.createObjectURL(e.target.files[0]),
                        data: e.target.files[0],
                      };
                      setEditCustomer({ ...editCustomer, profile_image: img });
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group mt-3">
                  <label>
                    {" "}
                    Name:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editCustomer?.name}
                    onChange={(e) =>
                      setEditCustomer({ ...editCustomer, name: e.target.value })
                    }
                  />
                </div>

                <div className="col-md-6 form-group mt-3">
                  <label>
                    {" "}
                    Email:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editCustomer?.email}
                    onChange={(e) =>
                      setEditCustomer({
                        ...editCustomer,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group mt-3">
                  <label>
                    {" "}
                    Phone:<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editCustomer?.mobile}
                    onChange={(e) =>
                      setEditCustomer({
                        ...editCustomer,
                        mobile: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="col-md-6 form-group mt-3">
                  <label>
                    {" "}
                    Category:<span className="text-danger">*</span>
                  </label>

                  <Select
                    className="flex-grow-1"
                    name="category"
                    options={categories}
                    value={editCustomer?.category}
                    onChange={(e) => {
                      console.log("data", e);
                      setEditCustomer({ ...editCustomer, category: e });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mt-3 d-flex justify-content-start">
                  <div className="me-2 my-auto fw-bold">Status: </div>
                  <div
                    className="toggling form-check form-switch"
                    onClick={(e) =>
                      setEditCustomer({
                        ...editCustomer,
                        status: !editCustomer.status,
                      })
                    }
                  >
                    <input
                      className="form-check-input form-check-input-colored"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      onChange={(e) =>
                        setEditCustomer({
                          ...editCustomer,
                          status: !editCustomer.status,
                        })
                      }
                      checked={editCustomer.status ? true : false}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-lg btn-success"
                  onClick={saveEditCustomer}
                  disabled={isBtn}
                >
                  Update
                  {isBtn ? (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-danger"
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Customers;
