import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {GetPrivacyAndTerms,UpdatePrivacyAndTerms} from '../../../apis/CMS'
import { HTTPURL } from "../../../constants/Matcher";


const PrivacyAndTerms=()=>{

    
    const [privacyTerms,setPrivacyTerms]=useState('')

  
        const [isBtn2,setIsBtn2]=useState(false);

        useEffect(()=>{
          
            getPrivacyAndTerms();
        },[])
      
        
       
         
         
        const getPrivacyAndTerms=async()=>{
            
            let result = await GetPrivacyAndTerms();
            if(result?.status){
                if(result?.privacy_and_terms){
                   setPrivacyTerms(result.privacy_and_terms);
                }

            }
        }

         const handlePdfUpload = (e) => {
                 setPrivacyTerms( e.target.files[0]);
   
         }

        
        const addPrivacyAndTerms=async()=>{
           
            setIsBtn2(true);
            const form = new FormData();
            form.append('privacy_and_terms', privacyTerms);
            
            let result = await UpdatePrivacyAndTerms(form);
            setIsBtn2(false);
            if(result?.status){
                            getPrivacyAndTerms();

                toast.success(result?.message);
            }else{
                toast.error(result?.message);
            }
            
        }
        
        
        
    return (
        <>
        <div className="right-nav admin-county  col-4 align-self-center" >
           
         
               <h4 className="mb-5 m-3">Privacy Policy & Terms of Service</h4>
               <form class="form-inner">
                <div className='row'>
                    <div className='col-md-12 col-sm-12'>
                       
                      
                                <div className='form-group mt-4 text-center'>
                              
                                   <input type='file' onChange={handlePdfUpload}  id='file' accept="application/pdf" />
                                  
                                </div>
                               
                                <div className='form-group mt-4'>
                                    <div className="modal-footer">
                                     <a className="btn btn-lg btn-info" href={HTTPURL+"/uploads/terms/"+privacyTerms} target="_blank" >View</a>
                                        <button type='button' onClick={()=>addPrivacyAndTerms()} className="btn btn-lg btn-success" disabled={isBtn2}>Save
                                        {isBtn2?<div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                        </div>:''}
                                        </button>
                                    </div>
                                </div>
                            

                    </div>
                </div>
             
             </form>
          

      
           

        </div>
        </>
    )
}

export default PrivacyAndTerms;

