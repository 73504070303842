import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable";
import {
  GetCounties,
  AddCounty,
  UpdateCounty,
  UpdateCountyStatus,
  DeleteCounty,
  AddReferral,
  GetReferral,
  DeleteReferral,
  updateReferralStatus,
  UpdateReferral,
  GetStatusList,
  AddOrderStatus,
  DeleteOrderStatus,
  UpdateOrderStatus,
} from "../../../apis/Settings";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import OrderDataTable from "../orders/OrderDataTable";
import DataLoader from "../../../Utils/DataLoader";

export const columns = [
  {
    id: "Id",
    selector: (row) => row.status_bar,
    sortable: true,
  },
  {
    id: "Id",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: "Label",
    selector: (row) => row.label,
    sortable: true,
  },
  {
    name: "Color",
    selector: (row) => row.color,
    sortable: true,
  },
  {
    name: "ACTION",
    selector: (row) => row.action,
    sortable: false,
    width: "160px",
  },
];

const OrderStatus = () => {
  const [data, setData] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(data?.status === "1");

  const [referral, setReferral] = useState({});

  const [editReferral, setEditReferral] = useState({});

  const [id, setId] = useState();
  const [isBtn, setIsBtn] = useState(false);

  async function getData() {
    const result = await GetStatusList();
    // setData(result?.data[0]);
    const arrayData = result?.data?.map((elem, index) => {
      const { id, status, color, label, created_at } = elem;

      return {
        order_no: id,
        status_bar: (
          <div className="status_bar">
            {" "}
            <span style={{ backgroundColor: color }}></span>{" "}
          </div>
        ),
        status: (
          <div className="card_data">
            {" "}
            <div className="light_heading"> Status : </div>{" "}
            <div className="card_item_text">{status}</div>{" "}
          </div>
        ),
        label: (
          <div className="card_data">
            {" "}
            <div className="light_heading"> Label : </div>{" "}
            <div className="card_item_text">{label}</div>{" "}
          </div>
        ),
        color: (
          <div className="card_data">
            {" "}
            <div className="light_heading"> Color : </div>{" "}
            <div
              style={{ backgroundColor: color, color: "white", padding: "4px" }}
              className="card_item_text"
            >
              {color}
            </div>{" "}
          </div>
        ),
        action: (
          <>
            {" "}
            <button
              className="btn btn-sm btn-info"
              onClick={(e) => EditHandler(elem)}
            >
              Edit
            </button>
            <button
              className="btn btn-sm btn-danger mx-1"
              onClick={(e) => DeleteHandler(elem)}
            >
              Delete
            </button>
          </>
        ),
      };
    });

    console.log(arrayData);
    setData(arrayData, setShowResults(true));
  }

  const DeleteHandler = async (data) => {
    if (data) {
      let response = await DeleteOrderStatus(data.id);
      if (response?.status) {
        toast.success(response?.message);
        getData();
      } else {
        toast.error(response?.message);
      }
    }
  };

  const EditHandler = async (data) => {
    console.log(data);
    setReferral({
      id: data.id,
      status: data.status,
      label: data.label,
      color: data.color,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsBtn(true);

    let response;
    if (referral?.id) {
      response = await UpdateOrderStatus(
        referral?.id,
        referral.status ?? data?.status,
        referral.label ?? data?.label,
        referral.color ?? data?.color
      );
      
    } else {
    
      response = await AddOrderStatus(
        referral.status,
        referral.label,
        referral.color
      );


      
    }
    setReferral({});
    setIsBtn(false);
    if (response?.status) {
      document.querySelectorAll('.fields').forEach(input => {
        input.value = '';  
      });
      
      toast.success(response?.message);
      // closeModal();
      getData();
    } else {
      toast.error(response?.message);
    }
  };

  // const handleCheckboxChange = (e) => {
  //   const newStatus = e.target.checked ? "1" : "0";
  //   setIsChecked(e.target.checked);
  //   setReferral({
  //     ...referral,
  //     status: e.target.checked,
  //   });
  // };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="right-nav admin-county py-4 px-2" id="admin-county">
      <div className="modal-inner-content">
        <h3>Status Settings</h3>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <div className="row my-5">
            <div className="col-md-4">
              <div className="form-group ">
                <label>
                  {" "}
                  Status <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control fields"
                  value={referral?.status}
                  placeholder="Status"
                  onChange={(e) =>
                    setReferral({
                      ...referral,
                      status: e.target.value,
                    })
                  }
                />
                {/* <select
                  onChange={(e) =>
                    setReferral({
                      ...referral,
                      status: e.target.value,
                    })
                  }
                  className="form-control"
                >
                  <option value="">Select</option>
                  <option value="1">ON</option>
                  <option value="0">OFF</option>
                </select> */}
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group ">
                <label>
                  Label <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className="form-control fields"
                  placeholder="Label "
                  value={referral?.label}
                  onChange={(e) =>
                    setReferral({
                      ...referral,
                      label: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group ">
                <label>
                  {" "}
                  Colors <span className="text-danger">*</span>
                </label>
                <input
                  type="color"
                  name=""
                  id=""
                  className="w-100 fields"
                  value={referral?.color}
                  onChange={(e) =>
                    setReferral({
                      ...referral,
                      color: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group ">
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-lg btn-success"
                    disabled={isBtn}
                  >
                    Save
                    {isBtn ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="right-nav py-4 px-2" id="dash-event">
          <div className="container-fluid order-status">
            <h3>
              {" "}
              <b>Status List</b>{" "}
            </h3>
            {showResults ? (
              <>
                <OrderDataTable columns={columns} data={data} />
              </>
            ) : (
              <DataLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
