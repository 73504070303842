import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { update_password } from './../../apis/Items'
import { Link } from "react-router-dom";
import axios from "axios";
import { HTTPURL } from "../../constants/Matcher";

const MyProfile = () => {

    const [passwords, setPasswords] = useState({
        current: "",
        password: "",
        confirm_password: ""
    })
    const [get_email, set_email] = useState();

    const [isBtn, setIsBtn] = useState(false)
    const [isBtn2, setIsBtn2] = useState(false)
    const [pwdEye, setPwdEye] = useState(false);
    const [newPwdEye, setNewPwdEye] = useState(false);
    const [confirmPwdEye, setConfirmPwdEye] = useState(false);

    useEffect(() => {
        let email = localStorage.getItem("email")
        if (email) {
            set_email(email)
        }
    }, [])
    const changePassword = async () => {
        setIsBtn2(true)
        if (!passwords?.current || !passwords?.password || !passwords?.confirm_password) {
            toast.error("All fields are required")
            setIsBtn2(false)
            return;
        } else {
            if (passwords?.password !== passwords?.confirm_password) {
                toast.error("Password is not matched.")
                setIsBtn2(false)
                return;
            }
            
             const response = await axios.post(HTTPURL + "/api/update-driver-password", {  oldpassword: passwords?.current, newpassword: passwords?.password},{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
         let result = response.data;
         
            setIsBtn2(false)
            if (result?.status) {
                toast.success(result?.message)
                setPasswords({
                    current: "",
                    password: "",
                    confirm_password: ""
                })

            } else {
                toast.error(result?.message)
            }
        }
    }
    const showCurrent = () => {
        let pas = !pwdEye;
        setPwdEye(pas)
    }
    const showPassword = () => {
        let pas = !newPwdEye;
        setNewPwdEye(pas)
    }
    const showConfirm = () => {
        let pas = !confirmPwdEye;
        setConfirmPwdEye(pas)
    }
    const handleEmailSubmit = async () => {
        
        if (get_email == "") {
            toast.error("Email is required.")
            return;
        } else {
            setIsBtn(true)
            
        const response = await axios.post(HTTPURL + "/api/update-driver-email", { email: get_email},{
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        })
        
         let result = response.data;
        
            setIsBtn(false)
            if (result?.status) {
                toast.success(result?.message)
                localStorage.setItem("email", get_email)
            } else {
                toast.error(result?.message)
            }
        }
    }
    return (
        <div className="container-fluid" >
            <div className="row justify-content-center" >
                <div className=" col-md-12 col-lg-10   col-xl-8   align-self-center" >
                    <div className="card mt-3" id="my-profile">
                        <div className="card-header">
                            <h3>My Profile  <Link to='/driver-dashboard' className="float-end">Go to dashboard</Link></h3>

                        </div>


                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className='form-group '>
                                        <label>Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            value={get_email}
                                            onChange={(e) =>
                                                set_email(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2">

                                    <div className="form-group ">
                                        <label>&nbsp;</label>
                                        <button type='button' className="btn btn-lg btn-success" onClick={handleEmailSubmit} disabled={isBtn} >Submit
                                            {isBtn && <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <div className='form-group mt-4 password-eye'>
                                        <label>Current password <span className="text-danger">*</span></label>
                                        <input
                                            type={`${pwdEye ? 'text' : 'password'}`}
                                            className="form-control"
                                            placeholder="Current password"
                                            value={passwords?.manage_name}
                                            onChange={(e) =>
                                                setPasswords({ ...passwords, current: e.target.value })
                                            }
                                        />
                                        <div className="close_eye pt-2" onClick={showCurrent}>
                                            {pwdEye ? <i className="fa-solid fa-eye"></i>
                                                :
                                                <i className="fa-solid fa-eye-slash"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="form-group mt-4 password-eye">
                                        <label>New Password <span className="text-danger">*</span></label>
                                        <input
                                            type={`${newPwdEye ? 'text' : 'password'}`}
                                            className="form-control"
                                            placeholder="Password"
                                            value={passwords?.password}
                                            onChange={(e) =>
                                                setPasswords({ ...passwords, password: e.target.value })
                                            }
                                        />
                                        <div className="close_eye  pt-2" onClick={showPassword}>
                                            {newPwdEye ? <i className="fa-solid fa-eye"></i>
                                                :
                                                <i className="fa-solid fa-eye-slash"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-3">
                                    <div className="form-group mt-4 password-eye">
                                        <label>Confirm Password <span className="text-danger">*</span></label>
                                        <input
                                            type={`${confirmPwdEye ? 'text' : 'password'}`}
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            value={passwords?.confirm_password}
                                            onChange={(e) =>
                                                setPasswords({ ...passwords, confirm_password: e.target.value })
                                            }
                                        />
                                        <div className="close_eye  pt-2" onClick={showConfirm}>
                                            {confirmPwdEye ? <i className="fa-solid fa-eye"></i>
                                                :
                                                <i className="fa-solid fa-eye-slash"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-2">
                                    <div className="form-group mt-4">
                                        <label>&nbsp;</label>
                                        <button type='button' className="btn btn-lg btn-success" onClick={changePassword} disabled={isBtn2}>Submit
                                            {isBtn2 && <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile;