import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import {
  HTTPURL,
  REACT_APP_GOOGLE_MAPS_API_KEY
} from "../../constants/Matcher";
import DataLoader from "../../Utils/DataLoader";

const containerStyle = {
  width: "100%",
  height: "600px"
};

const LaundryOrdersMapView = () => {
  const [data, setData] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY // Set your Google Maps API Key in the .env file
  });

  // Function to get user location
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        (error) => console.error("Error getting user location:", error),
        { enableHighAccuracy: true }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  // Function to fetch order data using fetch API
  const getData = async () => {
    try {
      const response = await fetch(`${HTTPURL}/api/driver-order-list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("token")
        }
      });

      // Check if response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const orderData = result?.data || [];

      const arrayData = orderData.map((elem) => {
        const {
          id,
          name,
          price,
          unit,
          date,
          delivery_day,
          estimated_loads,
          total_loads,
          order_status,
          category_title,
          delivery_address,
          pickup_address,
          pickup_lat,
          pickup_lng
        } = elem;

        return {
          order_no: id,
          name: name.split(" ")[0],
          category_title,
          price: unit === 1 ? `${price}/bag` : `${price}/lb`,
          pickup: pickup_address,
          delivery: delivery_address,
          pickup_lat,
          pickup_lng,
          estimated_loads:
            unit === 1 ? `${estimated_loads} bags` : `${estimated_loads} lbs`,
          total_loads:
            unit === 1 ? `${total_loads} bags` : `${total_loads} lbs`,
          status:
            ["New", "Washed", "Dry", "Folded", "Packed", "Picked"][
              order_status
            ] || "Unknown"
        };
      });

      setData(arrayData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleNavigate = (lat, lng) => {
    if (userLocation) {
      const { lat: userLat, lng: userLng } = userLocation;
      const url = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${lat},${lng}&travelmode=driving`;
      window.open(url, "_blank");
    } else {
      console.error("User location not available.");
      alert("User location not available.");
    }
  };

  // Fetch user location and order data on component mount
  useEffect(() => {
    getUserLocation();
    getData();
  }, []);

  // Display loading indicator if data is not loaded
  if (!isLoaded || isLoading) return <DataLoader />;

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 mt-3">
          <div className="card" id="my-profile">
            <div className="card-header">
              <h4>Laundry Orders (Map View)</h4>
            </div>
            <div className="card-body px-0 py-2">
              {userLocation ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={userLocation}
                  zoom={12}
                >
                  {/* User's current location marker */}
                  <Marker
                    position={userLocation}
                    icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                  />

                  {/* Display markers for each order */}
                  {data.map((order, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: order.pickup_lat,
                        lng: order.pickup_lng
                      }}
                      label={order.name}
                      title={`Order #${order.order_no}`}
                      onClick={() =>
                        handleNavigate(order.pickup_lat, order.pickup_lng)
                      }
                    />
                  ))}
                </GoogleMap>
              ) : (
                <p>Loading user location...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaundryOrdersMapView;
