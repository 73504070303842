import axios from "axios";
import React, { useEffect, useState } from "react";
import { HTTPURL } from "../../constants/Matcher";

import { Link } from "react-router-dom";
import DataLoader from "../../Utils/DataLoader";
import OrderDataTable from "./OrderDataTable";
import { Modal, ModalHeader } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import dateFormat from 'date-and-time';



export const columns = [
    {
        name: " ID",
        selector: row => row.order_no,
        sortable: true,
        width: "80px",
    },
    {
        name: "Customer",
        width: "120px",
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Price",
        width: "80px",
        selector: row => row.price,
        sortable: true,
    },
    {
        name: "Category",
        width: "100px",
        selector: row => row.category_title,
        sortable: true,
    },
    {
        name: "PICKUP",
        width: "250px",
        selector: row => row.pickup,
        sortable: true,
    },
    {
        name: "DELIVERY",
        selector: row => row.delivery,
        sortable: true,
        width: "250px",
    },
    {
        name: "STATUS",
        selector: row => row.status,
        width: "90px",
    },
    {
        name: "Es. Bags/Wt.",
        selector: row => row.estimated_loads,
        sortable: true,

    },
    {
        name: "Bags/Wt.",
        selector: row => row.total_loads,
        sortable: true,

    }

];
const LaundryOrdersHistory = () => {
    
    const [data, setData] = useState([]);
    const [current_step, setCurrent_step] = useState("1");

    const [steps, setsteps] = useState([1, 2, 3, 4]);
    const [subSteps, setSubSteps] = useState(["Wash", "Dry", "Fold", "Pack"]);
    const [details, setDetails] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [showResults, setShowResults] = useState(false);
    


    async function get_data() {
        try {
            var todaysDate = new Date();

  const response = await fetch(HTTPURL + `/api/driver-history-orders`, {
            method: "GET",
            headers: { "Content-Type": "application/json" ,'Authorization': localStorage.getItem('token')},
        });
           const result = await response.json();
         
   //         console.log(result);
            setOrderDetails(result?.data);
   
            const arrayData = result?.data?.map((elem, index) => {
                const {
                    id, folder_instructions, driver_instructions, order_id, price, unit, date,  delivery_day, estimated_loads, total_loads, order_type, 
                    driver, order_images, order_status, credit, folder, name, email, mobile, order_type_id, paid_amount,category_title,
                    delivery_address,pickup_address
                } = elem;

                var pickup_date = new Date(date);


                return {
                    order_no: id,
                    name: name.replace(/ .*/,''),
                    email: email,
                    mobile: mobile,
                    category_title:category_title,
                    price: (unit === 1) ? price + '/bag' : + price + '/lb',
                    pickup: dateFormat.format(new Date(date), 'ddd, MMM DD, YY')  ,
                    pickupdate: date  ,
                    delivery: delivery_day ,
                    folder_instructions: folder_instructions,
                    driver_instructions: driver_instructions,
                    total_loads: (unit === 1) ? total_loads + ' bags' : + total_loads + ' lbs',
                    estimated_loads: (unit === 1) ? estimated_loads + ' bags' : + estimated_loads + ' lbs',
                    order_type: order_type,
                    delivery_address: delivery_address,
                    pickup_address: pickup_address,
                    paid_amount: paid_amount,
                    color: (pickup_date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) ? 1 : 2,
                    status: order_status == 0 ? "New" : order_status == 1 ? "Washed" : order_status == 2 ? "Dry" : order_status == 3 ? "Folded" : order_status == 4 ? "Packed" : order_status == 8 ? "Picked" : '',
                };
            });

            console.log(arrayData);
            setData(arrayData, setShowResults(true));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        get_data();
     
    }, []);

 

return (
     <div className="container-fluid" >
            <div className="row justify-content-center" >
                <div className=" col-12  mt-3" >
                    <div className="card" id="my-profile">
                        <div className="card-header">
                        <h4>Laundry Orders (History)</h4>

                        </div>


                        <div className="card-body px-0 py-2">

            {showResults ? (
                <>
                    <OrderDataTable columns={columns} data={data} />

                </>
            ) : (
                <DataLoader />
            )}
        </div>
 </div>
  </div>
   </div>
    </div>
);
};
export default LaundryOrdersHistory;
