import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  RequireAuthAdmin,
  AdminLogout,
  RequireAuthDriver,
  DriverLogout
} from "../Utils/Utils";

import { Login, AdminLayout, AdminDashboard } from "../components/admin/Index";
import CustomersList from "../components/admin/users/CustomersList";
import LaundryOrdersList from "../components/admin/orders/LaundryOrdersList";
import LaundryOrdersHistory from "../components/admin/orders/LaundryOrdersHistory";
import OrderDetails from "../components/admin/orders/OrderDetails";
import QrCodes from "../components/admin/orders/QrCodes";
import DriverDetails from "../components/admin/users/DriverDetails";
import DriversList from "../components/admin/users/DriversList";
import CustomerDetails from "../components/admin/users/CustomerDetails";
import Folders from "../components/admin/users/Folders";
import NewBooking from "../components/admin/orders/NewBooking";
import AddCredit from "../components/admin/orders/AddCredit";
import MyProfile from "../components/admin/MyProfile";
import PrivacyAndTerms from "../components/admin/cms/PrivacyAndTerms";
import CustomerInstructions from "../components/admin/cms/CustomerInstructions";
import Counties from "../components/admin/settings/Counties";
import TimeSlots from "../components/admin/settings/TimeSlots";
import DeliveryDays from "../components/admin/settings/DeliveryDays";
import Categories from "../components/admin/settings/Categories";
import Prices from "../components/admin/settings/Prices";
import Holidays from "../components/admin/settings/Holidays";

import { DriverLayout, DriverDashboard } from "../components/driver/Index";
import DriverLaundryOrdersList from "../components/driver/LaundryOrdersList";
import DriverLaundryOrdersHistory from "../components/driver/LaundryOrdersHistory";
import DriverOrderDetails from "../components/driver/OrderDetails";
import DriverMyProfile from "../components/driver/MyProfile";
import Referral from "../components/admin/settings/Referral";
import Payment from "../components/admin/orders/PaymentHistory";
import OrderStatus from "../components/admin/settings/OrderStatus";
import CreditTable from "../components/admin/orders/CreditTable";
import RemoveCredit from "../components/admin/orders/RemoveCredit";
import RemoveCreditTable from "../components/admin/orders/RemoveCreditTable";
import LaundryOrdersMapView from "../components/driver/LaundryOrdersMapView";

function CreateRoute() {
  const userData = localStorage.getItem("userData");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={!userData ? <Navigate to="/login" /> : <Login />}
        />
        <Route
          path="/login"
          element={userData ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route element={<RequireAuthAdmin />}>
          <Route path="/" element={<AdminLayout />}>
            <Route path="dashboard" element={<AdminDashboard />} />

            <Route path="cms-privacy-and-terms" element={<PrivacyAndTerms />} />
            <Route
              path="cms-customer-instructions"
              element={<CustomerInstructions />}
            />

            <Route path="customers-list" element={<CustomersList />} />

            <Route path="new-booking/:id" element={<NewBooking />} />
            <Route path="add-credit/:id" element={<AddCredit />} />

            <Route
              path="laundary-orders-list"
              element={<LaundryOrdersList />}
            />
            <Route
              path="laundary-orders-history"
              element={<LaundryOrdersHistory />}
            />
            <Route path="payment-history" element={<Payment />} />
            <Route path="order-details/:order_id" element={<OrderDetails />} />
            <Route path="qr-codes" element={<QrCodes />} />

            <Route path="drivers-list" element={<DriversList />} />
            <Route path="driver-details/:id" element={<DriverDetails />} />
            <Route path="folders-list" element={<Folders />} />

            <Route path="customer-details/:id" element={<CustomerDetails />} />
            <Route path="my-profile" element={<MyProfile />} />

            <Route path="counties" element={<Counties />} />
            <Route path="time-slots" element={<TimeSlots />} />
            <Route path="delivery-days" element={<DeliveryDays />} />
            <Route path="categories" element={<Categories />} />
            <Route path="prices" element={<Prices />} />
            <Route path="holidays" element={<Holidays />} />
            <Route path="referral" element={<Referral />} />
            <Route path="credit-history" element={<CreditTable />} />
            <Route path="remove-credit/:id" element={<RemoveCredit />} />
            <Route
              path="remove-credit-history"
              element={<RemoveCreditTable />}
            />
            <Route path="order-status-setting" element={<OrderStatus />} />
          </Route>

          <Route path="logoutAdmin" element={<AdminLogout />} />
        </Route>

        <Route element={<RequireAuthDriver />}>
          <Route path="/" element={<DriverLayout />}>
            <Route path="driver-dashboard" element={<DriverDashboard />} />
            <Route
              path="driver-order-details/:order_id"
              element={<DriverOrderDetails />}
            />
            <Route
              path="driver-laundary-orders-list"
              element={<DriverLaundryOrdersList />}
            />
            <Route
              path="driver-laundary-orders-history"
              element={<DriverLaundryOrdersHistory />}
            />
            <Route
              path="driver-laundary-orders-mapview"
              element={<LaundryOrdersMapView />}
            />

            <Route path="driver-my-profile" element={<DriverMyProfile />} />
          </Route>
          <Route path="logoutDriver" element={<DriverLogout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default CreateRoute;
