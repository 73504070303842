export const HTTPURL = (process.env.REACT_APP_MODE == "sandbox")?"http://127.0.0.1:8000":"https://api.weclea.com";
// export const HTTPURL = "http://localhost:8000";
export const HTTPURLFRONT =
  process.env.REACT_APP_MODE == "sandbox"
    ? "http://localhost:3001/app"
    : "https://weclea.com/app";
export const APPID =
  process.env.REACT_APP_MODE == "sandbox"
    ? "sandbox-sq0idb-PmTS4BpezjwVYkF9gAaS-A"
    : "sq0idp-Nwsf6t3NJxjVcxfYImndvQ";
// export const APPID = (process.env.REACT_APP_MODE == "sandbox")?"sandbox-sq0idb--_YPlx7GfLa6wm-A3Biw_A":"sq0idp-Nwsf6t3NJxjVcxfYImndvQ";
export const APPLOCATION =
  process.env.REACT_APP_MODE == "sandbox" ? "L8SKJZEN57M5R" : "EGP5TVTY4G0X3";
export const REACT_APP_GOOGLE_MAPS_API_KEY =
  "AIzaSyC5vYkSJAY7McQJTUFe6WfK-OueaYcXLZ0";

export const SQUARESCRIPT =
  process.env.REACT_APP_MODE == "sandbox"
    ? "https://sandbox.web.squarecdn.com/v1/square.js"
    : "https://web.squarecdn.com/v1/square.js";
