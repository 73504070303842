import axios from "axios";
import React, { useEffect, useState } from "react";
import { HTTPURL } from "../../../constants/Matcher";

import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import {
  GetCreditHistory,
} from "../../../apis/Orders";
import OrderDataTable from "../../driver/OrderDataTable";

import Moment from "moment";

export const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
  },


  {
    name: "Customer",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    // width: "80px",
  },
  {
    name: "Mobile",

    selector: (row) => row.mobile,
    sortable: true,
  },{
    name: "Amount",

    selector: (row) => row.credit,
    sortable: true,
  },
  {
    name: "Type",

    selector: (row) => row.type,
    sortable: true,
  },

  {
    name: "Date",
    selector: (row) => row.date,
    sortable: true,
    
  },

  
];
const CreditTable = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);


  async function get_data() {
    try {
      var todaysDate = new Date();
      const result = await GetCreditHistory('added');
      const arrayData = result?.data?.map((elem, index) => {
        const {
          id,
          user_id,
          name,
          email,  
          booking_id,
          mobile,
          amount,
          updated_at,
          date,
          type,
          message,
        } = elem;
        console.log(message);
    
        var NDate = Moment(new Date(updated_at)).format(
          "ddd, MMM DD, YY , hh:mm A"
        );

        return {
          id: id,
          name: name,    
          email: email,
          mobile: mobile,
          order_type: type,
          date:NDate,
          credit: amount,
          type: type,
          message:message,
        };
      });

      setData(arrayData, setShowResults(true));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    get_data();

  }, []);


 

 




  

 







 

 

  return (
    <>
      <div className="right-nav py-4 px-2" id="dash-event">
        <div className="container-fluid credit-table">
          <h3>
            {" "}
            <b>Credit History</b>{" "}
          </h3>
          {showResults ? (
            <>
              <OrderDataTable columns={columns} data={data} />
            </>
          ) : (
            <DataLoader />
          )}
        </div>
      </div>

      


    </>
  );
};
export default CreditTable;
