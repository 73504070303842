import React, { useEffect, useState } from "react";
import axios from "axios";
import { HTTPURL } from "../../../constants/Matcher";
import CustomTable from "../../common/CustomTable";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import Select from 'react-select';
import { GetAllCustomers } from "./../../../apis/Users";
import { GetQrcodes } from "./../../../apis/Orders";
import QRCode from "react-qr-code";





export const columns = [
     {
        name: "Booking #",
        width: "130px",
        selector: row => row.booking_id,
        sortable: true,
    },
    {
        name: "Customer",
        selector: row => row.customer_name,
        sortable: true,
    },
    {
        name: "# of Bags",
        width: "120px",
        selector: row => row.no_of_bags,
        sortable: true,
    },
    {
        name: "Actions",
        selector: row => row.action,
        sortable: true,
        width: "140px",

    },

];
const QrCodes = () => {

    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isBtn, setIsBtn] = useState(false);
    const [id, setId] = useState();
    const [qrcodes, setQrcodes] = useState([]);



   

    useEffect(() => {
        getData();
    }, [])
    
 
  useEffect(() => {

        if (qrcodes && qrcodes.length > 0 ) {
            var mywindow = window.open('', '', '');

            if(mywindow){
                mywindow.document.write('<html><head><style>.col-6{width:50%;font-size:10pt;font-family: Arial, Helvetica, sans-serif;}.row{display:flex;width:2in;margin-left:0.10in;padding-top:0.05in;margin-bottom:0;padding-bottom:0}h4{margin-top:0.02in;margin-bottom:0;font-size:8pt;margin-left:0.10in;}.row h4{margin-top:0;margin-bottom:0.06in;font-size:12pt;margin-left:0;}@page{ size: auto;margin: 0mm;}</style>');
                mywindow.document.write('</head><body >');
                mywindow.document.write(document.getElementById("qrs").innerHTML);
                mywindow.document.write('</body></html>');
                mywindow.focus(); 
                mywindow.print();
            }
        }

    }, [qrcodes]);
  
    
    const printQrcodes = (e,elem) => {

        let qrs = [];
        const qrcodes = elem.qrcodes;
        qrcodes?.split(",").forEach(function (e) {

            const tmp = e.split("-");
            const left = <div class="col-6"><h4><b>weclea.com</b></h4>Cust ID: {elem.customer_id}<br />Bag No: {tmp[1]}<br />Code: {e}</div>;
            const right = <div class="col-6"><QRCode size={86} value={e} /></div>;

            const ele = <div class='row mt-5'>{left} {right}</div>;
            const sticker = <div>{ele}<h4 class="my-0">Premium Laundry Care by WeClea</h4></div>

            qrs.push(sticker);


        })
        console.log(qrs);
        
        setQrcodes(qrs) //simple value      

        
   
    }
  

   
 const isDestroy = (id, indx) => {
        setId(id)
        setIsConfirm(true)
    }
    const closeModal = () => {
        setIsConfirm(false)
    }

     async function deleteQrcodes() {
     
        const form = new FormData();
        form.append('booking_id', id);
      
        axios.post(HTTPURL + `/api/remove-qrcodes`, form, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
           // setIsLoader(false);

            if (response?.data?.status) {

                 getData();
                 toast.success("QR codes has been deattached to this booking successfully.")

            } else {
                toast.error("Error in QR code attachment. Reason: " + response?.data?.message)

            }
        }).catch((error) => {
           // setIsLoader(false);
            toast.error("Error in QR Printing. Reason: " + error)

        })



     
    }

    async function getData() {
        const result = await GetQrcodes();
        console.log(result);
        if(result?.data){
          displayRecords(result.data)
        }
    }
    const displayRecords = (result) => {
        const arrayData = result?.map((elem, index) => {
           
            const {  customer_name,  customer_id,no_of_bags,qrcodes, booking_id} = elem;
            return {
                 index: index + 1,
                customer_name: customer_name,
                customer_id: customer_id,
                no_of_bags:  no_of_bags,
                booking_id:booking_id,
                qrcodes:qrcodes,
                action: <>
                    <a className='btn btn-sm btn-info me-1' onClick={(e) => printQrcodes(e, elem)}   ><i class="fa fa-print"></i></a>
                    <button className="btn btn-sm btn-danger" onClick={() => isDestroy(booking_id, index)}><i class="fa fa-trash"></i></button>
                </>,
            };
        });
        setData(arrayData);
        setShowResults(true)
    }

     

  



    return (
        <div className="right-nav admin-county  py-4 px-2" id="admin-county">
            <h3>QR Codes LIST</h3>
            
            {showResults ? <CustomTable columns={columns} data={data} /> : null}

            <Modal animation={false}
                show={isConfirm}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}

            >
                <div className="modal-inner-content">
                    <div className="modal-heading"><h3>Do you really want to delete this qrcode?</h3></div>
                    <div className="modal-footer">
                        <button type='button' onClick={deleteQrcodes} className="btn btn-lg btn-success" disabled={isBtn}>Yes
                            {isBtn ? <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : ''}
                        </button>
                        <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>No</button>
                    </div>
                </div>
            </Modal>
            
            
            
            
          
          
         <div id="qrs" className="col-12 d-none" style={{ maxWidth: 350 }}>
               {qrcodes}
        </div>
          
          
         
          
          
            
            
        </div>
    )
}

export default QrCodes