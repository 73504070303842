import axios from "axios";
import React, { useEffect, useState } from "react";
import { HTTPURL } from "../../../constants/Matcher";

import { Link } from "react-router-dom";
import DataLoader from "../../../Utils/DataLoader";
import { AssignDriver, AssignFolder, GetHistory, UpdateStatus, AddLoad, updateInstruction, GetPaymentHistory } from "../../../apis/Orders";
import OrderDataTable from "./OrderDataTable";
import { Modal, ModalHeader } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import dateFormat from 'date-and-time';



export const columns = [
    {
        name: " ID",
        selector: row => row.order_link,
        sortable: true,
     
    },
    {
        name: "Customer",
       
        selector: row => row.name,
        sortable: true,
    },
    {
        name: "Email",
       
        selector: row => row.email,
        sortable: true,
    },
    {
        name: "Phone",
       
        selector: row => row.mobile,
        sortable: true,
    },
    {
        name: "Amount",
    
        selector: row => row.amount,
        sortable: true,
    },

   

    {
        name: "Credit",
        selector: row => row.credit,
        sortable: true,
               

    }, {
        name: "Date",
        selector: row => row.pickup_date,
        sortable: true,
               

    },
    // {
    //     name: "Actions",
    //     selector: row => row.action,
    //     sortable: true,
        

    // },

];
const Payment = () => {
    
    const [data, setData] = useState([]);
    const [current_step, setCurrent_step] = useState("1");

    const [steps, setsteps] = useState([1, 2, 3, 4]);
    const [subSteps, setSubSteps] = useState(["Wash", "Dry", "Fold", "Pack"]);
    const [details, setDetails] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [booking_id, setBookingId] = useState(0);
    const [booking_status, setBookingStatus] = useState("NA");

    const [isStatusUploadModalOpen, setIsStatusUploadModalOpen] = useState(false);


    const [isLoader, setIsLoader] = useState(false);
    const [getSearch, setSearch] = useState();
    const [isAction, setIsAction] = useState(false);
    const [driverList, setDriverList] = useState([])
    const [folderList, setFolderList] = useState([])
    const [actionType, setActionType] = useState(1)
    const [orderAssign, setOrderAssign] = useState({
        booking_id: 0,
        driver_id: 0,
        folder_id: 0,
        folder_instructions: '',
        driver_instructionss: '',
        booking_status: 0
    })
    const [id, setId] = useState(0);
    const [image, setImage] = useState([null, null, null, null, null]);
    const [imageFile, setImageFile] = useState([null, null, null, null, null]);
    const [isStatusImage, setIsStatusImage] = useState([false]);
    const [extraLoad, setExtraLoad] = useState([0]);



    async function get_data() {
        try {
            var todaysDate = new Date();


            const result = await GetPaymentHistory();
           console.log(result);
            setOrderDetails(result?.data);
   
            const arrayData = result?.data?.map((elem, index) => {
                
                const {
                    id, name, email, mobile , booking_id , amount ,payment_id , updated_at , credit
                } = elem;

                var pickup_date = new Date(updated_at);


                return {
                    id: id,
                    order_link:  <div  className="card_data"> <div className="light_heading"> Booking Id : </div> <div className="card_item_text"><a href={`/order-details/${id}`} >{id}</a></div>   </div>  ,
                    name_hide:name,
                    name: <div  className="card_data"> <div className="light_heading"> Name : </div> <div className="card_item_text">{name}</div>   </div>,
                    email_hide:email,
                    email: <div  className="card_data"> <div className="light_heading"> Email : </div> <div className="card_item_text">{email}</div>   </div>,
                    booking_id: booking_id,
                    amount_hide:amount,
                    amount:  <div  className="card_data"> <div className="light_heading"> Amount : </div> <div className="card_item_text">{amount}</div>   </div>,
                    credit_hide:credit,
                    credit: <div  className="card_data"> <div className="light_heading"> Credit : </div> <div className="card_item_text">{credit}</div>   </div>,
                    mobile_hide:mobile,
                    mobile: <div  className="card_data"> <div className="light_heading"> Mobile : </div> <div className="card_item_text">{mobile}</div>   </div>,
                    payment_id: payment_id,
                    pickup_date: <div  className="card_data"> <div className="light_heading"> Date : </div> <div className="card_item_text">{updated_at}</div>   </div>,
                    pickup_date_hide:updated_at
                    // action: <> <button className="btn btn-sm btn-danger" onClick={(e) => openActionModal(elem)}>Action</button></>,
                };
            });


            setData(arrayData, setShowResults(true));
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        get_data();
        getDriverList();
        getFolderList();
    }, []);

    const getFolderList = () => {

        axios.get(HTTPURL + `/api/folders-list`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setFolderList(response?.data?.data)
            }
        }).catch((error) => {

        })

    }

    const getDriverList = () => {
        axios.get(HTTPURL + `/api/get-active-drivers/all/0/100`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': localStorage.getItem('token')
            }
        }).then((response) => {
            if (response?.data?.status) {
                setDriverList(response?.data?.data?.rows)
            }
        }).catch((error) => {

        })
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const dataUrl = readerEvent.target.result;
                setImage(dataUrl);
                setImageFile(e.target.files[0]);
            };
            reader.readAsDataURL(file);
            setIsStatusUploadModalOpen(true);
        }
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setIsAction(false);
    };
    const closeStatusModal = () => {
        setIsStatusModalOpen(false);
    };
    const closeStatusUploadModal = () => {
        setIsStatusUploadModalOpen(false);
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // initialSlide: 1||0
    };



    const openActionModal = (order) => {
        setActionType(1)
        setIsAction(true);
        setOrderAssign({ ...orderAssign, booking_id: order?.id, driver_id: order.driver_id, folder_id: order.folder_id, folder_instructions: order?.folder_instructions, driver_instructions: order?.driver_instructions, booking_status: order?.order_status, booking_unit: order?.unit })
        setId(order?.user_id)
        if (order?.unit == 2) {
            setIsStatusImage([false, false, false, false, false]);
            setImageFile([null, null, null, null, null]);
            setImage([null, null, null, null, null]);

            setExtraLoad([0, 0, 0, 0, 0])
        }
        else {
            setIsStatusImage([false]);
            setImageFile([null]);
            setImage([null]);

            setExtraLoad([0])


        }

    }


    const changeAction = (value, e) => {
        setActionType(Number(value))
       // setOrderAssign({ ...orderAssign, driver_id: 0 })
       // setOrderAssign({ ...orderAssign, folder_id: 0 })
        let elements = document.getElementsByClassName("action-btn")
        for (let ele of elements) {
            ele.classList.remove('active-status')
        }
        e.target.classList.add('active-status');
        setIsStatusImage(false);

    }
    const handleSubmit = async () => {

            const form = new FormData();
            form.append('booking_id', orderAssign?.booking_id);
            form.append('status', orderAssign?.booking_status);


            var result = await UpdateStatus(form, '/api/update-booking-status');
            if (result?.status) {
                toast.success(result?.message)
                closeModal();
                get_data();
            } else {
                toast.error(result?.message)
            }

       
    
}




const addNewElement = (e) => {
    var tmp = Object.values({ ...extraLoad });
    if (tmp.length < 20) {
        tmp.push(0);
        setExtraLoad(tmp);

        tmp = Object.values({ ...isStatusImage });
        tmp.push(0);
        setIsStatusImage(tmp);

        tmp = Object.values({ ...imageFile });
        tmp.push(0);
        setImageFile(tmp);

        tmp = Object.values({ ...image });
        tmp.push(0);
        setImage(tmp);
    }
    else {
        toast.error("Max 20 Wt. scale reading allowed.")
    }

}



return (
    <>
        <div className="right-nav py-4 px-2" id="dash-event">
            <div className="container-fluid">

            <h3> <b> Payment History </b></h3>
            {showResults ? (
                <>
                    <OrderDataTable columns={columns} data={data} />

                </>
            ) : (
                <DataLoader />
            )}
            </div>
        </div>













        <Modal animation={false}
            show={isAction}
            onHide={closeModal}
            backdrop="static"
            keyboard={false}
            id="action-modal"
        >
            <div className="modal-inner-content">
                <div className="modal-heading"><h2>Update Status</h2></div>
                
                <div className="col-lg-12 col-md-6 mt-4">
                    <label>Order Status</label>
                    <div className='form-group mt-2'>
                        <select onChange={(e) => setOrderAssign({ ...orderAssign, booking_status: parseInt(e.target.value) })}>
                            <option selected={orderAssign?.booking_status === 0} value="0">New</option>
                            <option selected={orderAssign?.booking_status === 8} value="8">Order Pickup</option>
                            <option selected={orderAssign?.booking_status === 1} value="1">Wash</option>
                            <option selected={orderAssign?.booking_status === 2} value="2">Dry</option>
                            <option selected={orderAssign?.booking_status === 3} value="3">Fold</option>
                            <option selected={orderAssign?.booking_status === 4} value="4">Pack</option>
                            <option selected={orderAssign?.booking_status === 6} value="6">Completed</option>

                        </select>
                    </div>
                    
                </div>
              

                <div className="modal-footer">
                    <button type='button' className="btn btn-lg btn-success" onClick={handleSubmit} disabled={isLoader}>Submit
                        {isLoader ? <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : ''}
                    </button>
                    <button type='button' className="btn btn-lg btn-success" onClick={closeModal}>Cancel</button>
                </div>

            </div>
        </Modal>


    </>
);
};
export default Payment;
